/* eslint-disable react/display-name,react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import _ from 'lodash';
import t from '@/utilities/translate';
import { BaseLayoutRoute, EmptyLayoutRoute } from '@/routing/layout-wrapper';
import { isAuth, isGuest } from './auth-rules';
import { history } from '@/redux/history';
import { accessPermission } from '@/utilities/access';
import { accessRoles } from '@/constants';

import { route } from './_routers_';
import { canSeePlanFactRoles, canUpdateServiceCodesRoles } from '../components/common/sideleft/access';

const Redirector = (path) => class extends React.Component {
  UNSAFE_componentWillMount() {
    history.push(path);
  }

  render() {
    return <span />;
  }
};

const buildSpecialBreadcrumb = (getObjectFn, defaultParam = 'id') => {
  if (typeof getObjectFn === 'function') {
    return connect((state) => ({ state }))((props) => {
      let result;
      const { state, match } = props;
      try {
        result = getObjectFn(state, match.params);
      } catch (err) {
        result = <Icon type="loading" />;
      }
      return <span>{result}</span>;
    });
  }
  return (props) => <span>{props.match.params[defaultParam]}</span>;
};

export const routes = {

  index: {
    path: '/',
    link: () => '/',
    exact: true,
    layout: BaseLayoutRoute,
    component: isAuth(route.Dashboard),
    get breadcrumb(){return t('home/breadcrumb')},
    icon: 'home',
  },

  auth: {
    path: '/login',
    link: '/login',
    exact: true,
    layout: EmptyLayoutRoute,
    component: isGuest(route.LoginContainer),
  },

  logout: {
    path: '/logout',
    link: '/logout',
    exact: true,
    layout: EmptyLayoutRoute,
    component: isAuth(route.LogoutContainer),
    icon: 'logout',
    sidebarText: 'Выход',
  },

  api: {
    path: '/api',
    breadcrumb: 'API',
    layout: BaseLayoutRoute,
    component: isAuth(route.API),
  },

  '/profile/edit': {
    path: '/profile/edit',
    link: (ID) => '/profile/edit',
    breadcrumb: 'Edit profile',
    layout: BaseLayoutRoute,
    component: isAuth(route.ProfileEdit),
  },

  '/profile': {
    path: '/profile',
    link: (ID) => '/profile',
    breadcrumb: buildSpecialBreadcrumb((state, { id }) => {
      const meProfile = state.profile.get('me');
      return `ID: ${meProfile.id} ${meProfile.name ? `(${meProfile.name})` : ''}`;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.ProfileAdmin),
  },

  //-----------------------------------------
  //	Employees routes
  //-----------------------------------------

  employees: {
    path: '/salesmen_view',
    link: () => '/salesmen_view',
    get breadcrumb() { return t('employees/list/title'); },
    layout: BaseLayoutRoute,
    exact: true,
    component: isAuth(route.EmployeesAllContainer),
    icon: 'play-circle-o',
  },

  'employees/createOutletManager': {
    path: '/employees/add_new_employee/outlet_manager',
    link: () => `/employees/add_new_employee/outlet_manager`,
    exact: true,
    get breadcrumb() { return t('create/heading/employees'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER
    ])(route.EmployeesCreateContainer),
    icon: 'play-circle-o',
  },

  'employees/createSalesManager': {
    path: '/employees/add_new_employee/sales_manager',
    link: () => `/employees/add_new_employee/sales_manager`,
    exact: true,
    get breadcrumb() { return t('create/heading/employees'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER
    ])(route.EmployeesCreateContainer),
    icon: 'play-circle-o',
  },

  'employees/createByType': {
    path: '/employees/add_new_employee/:userType',
    link: ({ type }) => `/employees/add_new_employee/${type}`,
    exact: true,
    get breadcrumb() { return t('create/heading/employees'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.EmployeesCreateContainer),
    icon: 'play-circle-o',
  },

  'employees/create': {
    path: '/employees/add_new_employee',
    link: () => '/employees/add_new_employee',
    exact: true,
    breadcrumb: null,
    // get breadcrumb() { return t('employees/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.EmployeesCreateContainer),
    icon: 'play-circle-o',
  },

  'employees/role': {
    path: '/employees/(sales_manager|outlet_manager|top_manager)',
    link: () => '/employees/sales_manager|outlet_manager',
    breadcrumb: null,
    layout: BaseLayoutRoute,
    exact: true,
    component: isAuth(route.EmployeesEditContainer),
    icon: 'play-circle-o',
  },

  'sales_manager/edit': {
    path: '/employees/sales_manager/:id-:name/edit',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}/edit`.replace(/\s/g, '_'),
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY
    ])(route.EmployeesEditContainer),
    icon: 'play-circle-o',
  },

  'outlet_manager/edit': {
    path: '/employees/outlet_manager/:id-:name/edit',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}/edit`.replace(/\s/g, '_'),
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
    ])(route.EmployeesEditContainer),
    icon: 'play-circle-o',
  },

  'top_manager/edit': {
    path: '/employees/top_manager/:id-:name/edit',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}/edit`.replace(/\s/g, '_'),
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY
    ])(route.EmployeesEditContainer),
    icon: 'play-circle-o',
  },

  'employees/edit': {
    path: '/employees/(sales_manager|outlet_manager|top_manager)/:id-:name/edit',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}/edit`.replace(/\s/g, '_'),
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    exact: true,
    component: isAuth(route.EmployeesEditContainer),
    icon: 'play-circle-o',
  },

  'sales_manager/profile': {
    path: '/employees/sales_manager/:id([0-9]+)-:name',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state, { id }) => {
      let employeesName = null;
      if (history.location.pathname.indexOf('/edit') >= 0) {
        const employeesInfoState = state.employees.edit.get('info');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      } else {
        const employeesInfoState = state.employees.profile.get('user_profile');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      }
      return employeesName;
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER
    ])(route.EmployeesProfile),
  },

  'outlet_manager/profile': {
    path: '/employees/outlet_manager/:id([0-9]+)-:name',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state, { id }) => {
      let employeesName = null;
      if (history.location.pathname.indexOf('/edit') >= 0) {
        const employeesInfoState = state.employees.edit.get('info');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      } else {
        const employeesInfoState = state.employees.profile.get('user_profile');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      }
      return employeesName;
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.SALES_MANAGER,
      accessRoles.OUTLET_MANAGER
    ])(route.EmployeesProfile),
  },

  'employees/profile': {
    path: '/employees/(sales_manager|outlet_manager)/:id([0-9]+)-:name',
    link: ({ id, name, type = 'sales_manager' }) => `/employees/${type}/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state, { id }) => {
      let employeesName = null;
      if (history.location.pathname.indexOf('/edit') >= 0) {
        const employeesInfoState = state.employees.edit.get('info');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      } else {
        const employeesInfoState = state.employees.profile.get('user_profile');
        employeesName = `${employeesInfoState.last_name} ${employeesInfoState.name}`;
      }
      return employeesName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.EmployeesProfile),
  },

  //-----------------------------------------
  //	Manufacture routes
  //-----------------------------------------

  'manufacturers/edit': {
    path: '/manufacturers/:id/edit',
    link: (ID) => `/manufacturers/${ID}/edit`,
    layout: BaseLayoutRoute,
    exact: true,
    disableDefaults: true,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ManufactureEdit),
  },

  'manufacturers/create': {
    path: '/manufacturers/add_new_manufacturer',
    link: (ID) => '/manufacturers/add_new_manufacturer',
    get breadcrumb() { return t('manufacturers/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ManufactureCreate),
  },

  manufacturer: {
    path: '/manufacturers/:id',
    link: (ID) => `/manufacturers/${ID}`,
    // breadcrumb: 'Edit Manufacturer',
    exact: true,
    layout: BaseLayoutRoute,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const manufactureName = state.manufacture.get('current_manufacture').name;
      return manufactureName;
    }),
    component: isAuth(Redirector('/manufacturers')),
  },

  manufacturers: {
    path: '/manufacturers',
    exact: true,
    link: (ID) => '/manufacturers',
    breadcrumb() {return t('manufacture/title')},
    get sidebarText(){ return t('manufacture/sidebar') },
    layout: BaseLayoutRoute,
    component: isAuth(route.ManufactureList),
  },

  //-----------------------------------------
  //	Platform routes
  //-----------------------------------------

  'platform/edit': {
    path: '/product_classification/platform/:id/edit',
    link: (ID) => `/product_classification/platform/${ID}/edit`,
    layout: BaseLayoutRoute,
    exact: true,
    breadcrumb: 'Edit Platform',
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.PlatformAndEqEdit),
  },

  'platform/create': {
    path: '/product_classification/add_new_platform',
    link: () => '/product_classification/add_new_platform',
    get breadcrumb() { return t('platform/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.PlatformAndEqCreate),
  },

  'platform-': {
    path: '/product_classification/platform',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('platform/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'product-line-group': {
    path: '/product_classification/product_line_group',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('product-line-group/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'product-line': {
    path: '/product_classification/product_line',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('product-line/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'equipment-group': {
    path: '/product_classification/equipment_group',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('equipment-group/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'composite-level': {
    path: '/product_classification/composite_level',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('composite-level/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'estimate-description': {
    path: '/product_classification/estimate_description',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('estimate-description/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'model-description': {
    path: '/product_classification/model_description',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('model-description/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'base-machinery-description': {
    path: '/product_classification/base_machinery_description',
    layout: EmptyLayoutRoute,
    exact: true,
    get breadcrumb() { return t('base-machinery-description/breadcrumb'); },
    component: isAuth(Redirector('/product_classification')),
  },

  'platforms/id': {
    path: '/product_classification/platform/:id',
    link: (ID) => `/product_classification/platform/${ID}`,
    // breadcrumb: 'Edit Manufacturer',
    layout: BaseLayoutRoute,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const platfrormName = state.platform.get('current_platform').en;
      return platfrormName;
    }),
    component: isAuth(Redirector('/product_classification')),
  },

  platform: {
    path: '/product_classification',
    link: () => '/product_classification',
    get breadcrumb() { return t('routing/platform'); },
    // breadcrumb: 'Product options',
    exact: true,
    layout: BaseLayoutRoute,
    component: isAuth(route.PlatformAndEqList),
  },

  //-----------------------------------------
  //	Product routes
  //-----------------------------------------

  'product/create': {
    path: '/products/add_new_product',
    link: () => '/products/add_new_product',
    get breadcrumb() { return t('product/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductCreate),
  },

  'product/edit': {
    path: '/products/:id/edit',
    link: (ID) => `/products/${ID}/edit`,
    breadcrumb: 'Edit product',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductEdit),
  },

  'product/current': {
    path: '/(product|products)/:id',
    link: (ID) => `/products/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) =>
    // const productName = state.product.get('product_current').model_name;
      `ID ${state.product.get('product_current').id}`),
    layout: BaseLayoutRoute,
    component: isAuth(route.ProductCurrent),
  },

  product: {
    path: '/(products|product)',
    link: () => '/products',
    get breadcrumb() { return t('prduct/list/breadcrumb'); },
    exact: true,
    layout: BaseLayoutRoute,
    component: isAuth(route.ProductList),
  },

  //-----------------------------------------
  //	Country total
  //-----------------------------------------

  country_sales: {
    path: '/country_sales',
    link: () => '/country_sales',
    get breadcrumb() { return t('estimate/total_country/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.EstimateCountrySales),
  },

  //-----------------------------------------
  //	Estimate
  //-----------------------------------------

  // total_country_goals: {
  //   path: '/estimate_goals',
  //   link: () => '/estimate_goals',
  //   get breadcrumb() { return t('estimate/total_country/breadcrumb'); },
  //   layout: BaseLayoutRoute,
  //   component: isAuth(route.TotalCountryGoals),
  // },

	total_country_goals_v2: {
		path: '/estimate_goals',
		link: () => '/estimate_goals',
		get breadcrumb() { return t('estimate/total_country/breadcrumb'); },
		layout: BaseLayoutRoute,
		component: isAuth(route.TotalCountryGoalsV2),
	},

  'order-scheduled-history': {
    path: '/order-scheduled-history',
    link: () => '/order-scheduled-history',
    get breadcrumb() { return t('order-scheduled-history/history/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.OrderScheduledHistory),
  },

  'orders-scheduled': {
    path: '/orders-scheduled',
    link: () => '/orders-scheduled',
    get breadcrumb() { return t('orders-scheduled/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.OrdersScheduled),
  },



  //-----------------------------------------
  //	Dealer routes
  //-----------------------------------------

  'dealer/create': {
    path: '/dealers/add_new_dealer',
    link: () => '/dealers/add_new_dealer',
    get breadcrumb() { return t('dealer/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER
    ])(route.DealerCreate),
  },

  'dealer/edit': {
    path: '/dealers/:id/edit',
    link: ({ id, name }) => `/dealers/${id}-${name}/edit`.replace(/\s/g, '_'),
    breadcrumb: 'Edit dealer',
    layout: BaseLayoutRoute,
    component: isAuth(route.DealerEdit),
  },

  'dealer/co-worker': {
    path: '/employees/co_worker/:id-:name',
    link: ({ id, name }) => `/employees/co_worker/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: null,
    layout: BaseLayoutRoute,
    component: Redirector('/dealers/:id-:name'),
  },

  'dealer/profile': {
    path: '/dealers/:id-:name',
    link: ({ id, name }) => `/dealers/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const dealerName = state.dealer.get('current').name;
      return dealerName;
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.DealerProfile),
  },

  dealer: {
    path: '/dealers',
    link: () => '/dealers',
    exact: true,
    get breadcrumb() { return t('dealer/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.DealerList),
  },

  //-----------------------------------------
  //	Client
  //-----------------------------------------


  'contact/list': {
    path: '/customers/contacts',
    link: () => `/customers/contacts`,
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/customers')),
    get breadcrumb() {
      return t('contacts/lists/breadcrumbs');
    },
  },

  'contact/current': {
    path: '/customers/contacts/:id-name/:contact_id-:contact_name',
    link: ({client_id, id}) => `/customers/contacts/${client_id}/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/customers')),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const { last_name, first_name } = state.client.get('currentContact');
      const contactName = last_name + ' ' + first_name;
      return contactName;
    })
  },

  'contact/client': {
    path: '/customers/contacts/:client_id',
    link: ({id}) => `/customers/contacts/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/customers')),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const { customer_last_name, customer_first_name, customer_second_name } = state.client.get('clientCurrent');
      const clientName = customer_last_name + ' ' + customer_first_name + ' ' + customer_second_name;
      return clientName;
    })
  },

  'contact/update': {
    path: '/customers/:id-:name/contacts/:contact_id-:contact_name/edit',
    link: ({id, name, client_id, client_name}) => `/customers/${client_id}-${client_name}/contacts/${id}-${name}/edit`.replace(/\s/g, '_'),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER,
      accessRoles.TERRITORY_MANAGER
    ])(route.ContactUpdate),
    get breadcrumb() {
      return t('contacts/update/breadcrumbs');
    },
  },

  'contact/create': {
    path: '/customers/:id-:name/contacts/add_new_contact',
    link: ({id, name}) => `/customers/${id}-${name}/contacts/add_new_contact`.replace(/\s/g, '_'),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER,
      accessRoles.TERRITORY_MANAGER
    ])(route.ContactCreate),
    get breadcrumb() {
      return t('contacts/create/breadcrumbs');
    },
  },

  'technique/update': {
    path: '/customers/:id-:name/vehicles/:vehicle_id/edit',
    link: ({id, name, client_id, client_name}) => `/customers/${client_id}-${client_name}/vehicles/${id}/edit`.replace(/\s/g, '_'),
    layout: BaseLayoutRoute,
    component: isAuth(route.TechniqueUpdate),
    get breadcrumb() {
      return t('techniques/update/breadcrumbs');
    },
  },

  'technique/create': {
    path: '/customers/:id-:name/vehicles/add_new_vehicle',
    link: ({id, name}) => `/customers/${id}-${name}/vehicles/add_new_vehicle`.replace(/\s/g, '_'),
    layout: BaseLayoutRoute,
    component: isAuth(route.TechniqueCreate),
    get breadcrumb() {
      return t('techniques/create/breadcrumbs');
    },
  },

  'technique/list': {
    path: '/customers/vehicles',
    link: () => `/customers/vehicles`,
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/customers')),
    get breadcrumb() {
      return t('vehicles/lists/breadcrumbs');
    },
  },

  'client/create': {
    path: '/customers/add_new_customer',
    link: () => '/customers/add_new_customer',
    get breadcrumb() { return t('customer/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER
    ])(route.ClientCreate),
  },

  'client/edit': {
    path: '/customers/:id-:name/edit',
    link: ({ id, name }) => `/customers/${id}-${encodeURIComponent(name.replace(/\//g, '-'))}/edit`,
    get breadcrumb() { return t('common/edit'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER,
      accessRoles.TERRITORY_MANAGER
    ])(route.ClientEdit),
  },

  'client/profile': {
    path: '/customers/:id-:name',
    link: ({ id, name }) => `/customers/${id}-${encodeURIComponent(name.replace(/\//g, '-'))}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const clientName = state.client.get('clientCurrent').company_name;
      return clientName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.ClientProfile),
  },

  client: {
    path: '/customers',
    link: () => '/customers',
    get breadcrumb() { return t('customer/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.ClientList),
  },

  //-----------------------------------------
  //	Coverage goals
  //-----------------------------------------

  // coverage_goals: {
  //   path: '/coverage-goals',
  //   link: () => '/coverage-goals',
  //   get breadcrumb() {
	//   return t('coverage-goals/list/breadcrumbs');
  //   },
  //   layout: BaseLayoutRoute,
  //   component: accessPermission([
  //     accessRoles.COUNTRY_MANAGER,
  //     accessRoles.TERRITORY_MANAGER,
  //     accessRoles.COMPANY,
  //     accessRoles.TOP_MANAGER,
  //     accessRoles.PSS
  //   ])(route.CoverageGoals),
  // },

	//-----------------------------------------
	//	Coverage goals v2
	//-----------------------------------------

	coverage_goals_v2: {
		path: '/coverage-goals',
		link: () => '/coverage-goals',
		get breadcrumb() {
			return t('coverage-goals_v2/list/breadcrumbs');
		},
		layout: BaseLayoutRoute,
		component: accessPermission([
			accessRoles.COUNTRY_MANAGER,
			accessRoles.TERRITORY_MANAGER,
			accessRoles.COMPANY,
			accessRoles.TOP_MANAGER,
			accessRoles.PSS
		])(route.CoverageGoals_v2),
	},

  //-----------------------------------------
  //	Territorial
  //-----------------------------------------

  'territorial/edit': {
    path: '/employees/territory_manager/:id-:name/edit',
    link: ({ id, name }) => `/employees/territory_manager/${id}-${name}/edit`.replace(/\s/g, '_'),
    exact: true,
    get breadcrumb() { return t('territorial/edit/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.TerritorialEdit),
  },

  'territorial/create/withIn': {
    path: '/employees/add_new_territory_manager/:distributor',
    link: (distributor) => `/employees/add_new_territory_manager/${distributor}`,
    exact: true,

    get breadcrumb() { return t('territorial/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.TerritorialCreate),
  },

  'territorial/create': {
    path: '/employees/add_new_territory_manager',
    link: () => '/employees/add_new_territory_manager',
    exact: true,
    get breadcrumb() { return t('territorial/create/title'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.TerritorialCreate),
  },

  'territorial/role': {
    path: '/employees/territory_manager',
    link: ({ id, name }) => '/employees/territory_manager',
    exact: true,
    breadcrumb: null,
    layout: BaseLayoutRoute,
    component: isAuth(route.TerritorialProfile),
  },

  'territorial/profile': {
    path: '/employees/territory_manager/:id-:name',
    link: ({ id, name }) => `/employees/territory_manager/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const current = state.territorial.get('territorialCurrent');
      return `${current.last_name ?? ''} ${current.name ?? ''}`;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.TerritorialProfile),
  },

  //-----------------------------------------
  //	Pss
  //-----------------------------------------
  //
  'pss/edit': {
    path: '/product-sales-specialist/:id-:name/edit',
    link: ({ id, name }) => `/product-sales-specialist/${id}-${name}/edit`.replace(/\s/g, '_'),
    exact: true,
    get breadcrumb() { return t('pss/edit/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.PssEdit),
  },

  'pss/create': {
    path: '/product-sales-specialist',
    link: () => '/product-sales-specialist',
    exact: true,
    get breadcrumb() { return t('pss/create/title'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.PssCreate),
  },
  'pss/profile': {
    path: '/product-sales-specialist/:id-:name',
    link: ({ id, name }) => `/product-sales-specialist/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const current = state.pss.get('pssCurrent');
      const pssName = `${current.last_name} ${current.name}`;
      return pssName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.PssProfile),
  },

  //-----------------------------------------
  //	Locations
  //-----------------------------------------

  'country/create': {
    path: '/countries/add_new_country',
    link: () => '/countries/add_new_country',
    get breadcrumb() { return t('country/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CountryCreate),
  },

  'country/edit': {
    path: '/countries/:id/edit',
    link: (id) => `/countries/${id}/edit`,
    get breadcrumb() { return t('common/edit'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CountryEdit),
  },

  'country/profile': {
    path: '/countries/:id',
    link: (ID) => `/countries/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const countryName = state.locations.get('current@country').name;
      return countryName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/countries')),
  },

  country: {
    path: '/countries',
    link: () => '/countries',
    get breadcrumb() { return t('country/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.PSS
    ])(route.CountryList),
  },

  //-----------------------------------------
  //	Region
  //-----------------------------------------

  'region/create': {
    path: '/oblasts/add_new_oblast',
    link: () => '/oblasts/add_new_oblast',
    get breadcrumb() { return t('region/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.RegionCreate),
  },

  'region/edit': {
    path: '/oblasts/:id/edit',
    link: (id) => `/oblasts/${id}/edit`,
    get breadcrumb() { return t('common/edit'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.RegionEdit),
  },

  'region/profile': {
    path: '/oblasts/:id',
    link: (ID) => `/oblasts/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const regionName = state.locations.get('current@region').name;
      return regionName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/oblasts')),
  },

  region: {
    path: '/oblasts',
    link: () => '/oblasts',
    get breadcrumb() { return t('region/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.RegionList),
  },

  //-----------------------------------------
  //	Rayon
  //-----------------------------------------

  'district/create': {
    path: '/districts/add_new_district',
    link: () => '/districts/add_new_district',
    get breadcrumb() { return t('rayon/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
    ])(route.RayonCreate),
  },

  'district/edit': {
    path: '/districts/:id/edit',
    link: (id) => `/districts/${id}/edit`,
    get breadcrumb() { return t('common/edit'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
    ])(route.RayonEdit),
  },

  'district/profile': {
    path: '/districts/:id',
    link: (ID) => `/districts/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const rayonName = state.locations.get('current@rayon').name;
      return rayonName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/districts')),
  },

  district: {
    path: '/districts',
    link: () => '/districts',
    get breadcrumb() { return t('district/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.RayonList),
  },

  //-----------------------------------------
  //	Job title
  //-----------------------------------------

  'job/edit': {
    path: '/job_titles/:id/edit',
    link: (id) => `/job_titles/${id}/edit`,
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.JobEdit),
  },

  'job/create': {
    path: '/job_titles/add_new_job_title',
    link: () => '/job_titles/add_new_job_title',
    get breadcrumb() { return t('job/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.JobCreate),
  },

  'job/profile': {
    path: '/job_titles/:id',
    link: (ID) => `/job_titles/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const jobName = state.job.get('current').name;
      return jobName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/job_title')),
  },

  job: {
    path: '/job_titles',
    link: () => '/job_titles',
    get breadcrumb() { return t('job/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.JobList),
  },

  //-----------------------------------------
  //	Factories
  //-----------------------------------------

  'factories/edit': {
    path: '/factories/:id/edit',
    link: (id) => `/factories/${id}/edit`,
    breadcrumb: 'Edit',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.FactoryEdit),
  },

  'factories/create': {
    path: '/factories/create',
    link: () => '/factories/create',
    get breadcrumb() { return t('factories/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.FactoryCreate),
  },

  'factories': {
    path: '/factories',
    link: () => '/factories',
    get breadcrumb() { return t('factories/list/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.FactoriesList),
  },

  factory: {
	path: '/factories',
	link: () => '/factories',
	get breadcrumb() { return t('factories/list/breadcrumb'); },
	layout: BaseLayoutRoute,
	component: accessPermission([
		accessRoles.COUNTRY_MANAGER,
		accessRoles.TOP_MANAGER,
		accessRoles.COMPANY,
		accessRoles.TERRITORY_MANAGER,
		accessRoles.PSS
	])(route.FactoriesList),
  },

  //-----------------------------------------
  //	Product Line Group
  //-----------------------------------------

  'product-line-group/create': {
    path: '/product_classification/add_new_product_line_grouping',
    link: () => '/product_classification/add_new_product_line_grouping',
    get breadcrumb() { return t('productLineGroup/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductLineGroupCreate),
  },

  'product-line-group/edit': {
    path: '/product_classification/product_line_group/:id/edit',
    link: (id) => `/product_classification/product_line_group/${id}/edit`,
    breadcrumb: 'Edit Product Line Group',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductLineGroupEdit),
  },

  'product-line-group/profile': {
    path: '/product_classification/product_line_group/:id',
    link: (ID) => `/product_classification/product_line_group/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const productLineGroupName = _.get(state.productLineGroup.get('current'), 'en.name');
      return productLineGroupName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Product Line
  //-----------------------------------------

  'product-line/create': {
    path: '/product_classification/add_new_product_line',
    link: () => '/product_classification/add_new_product_line',
    get breadcrumb() { return t('product-line/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductLineCreate),
  },

  'product-line/edit': {
    path: '/product_classification/product_line/:id/edit',
    link: (id) => `/product_classification/product_line/${id}/edit`,
    breadcrumb: 'Edit Product Line',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ProductLineEdit),
  },

  'product-line/profile': {
    path: '/product_classification/product_line/:id',
    link: (ID) => `/product_classification/product_line/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const productLineName = _.get(state.productLine.get('current'), 'en.name');
      return productLineName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Farm Size
  //-----------------------------------------

  'farm-size/create': {
    path: '/farm_size_categories/add_new_farm_size_category',
    link: () => '/farm_size_categories/add_new_farm_size_category',
    get breadcrumb() { return t('farmSize/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.FarmSizeCreate),
  },

  'farm-size/edit': {
    path: '/farm_size_categories/:id/edit',
    link: (id) => `/farm_size_categories/${id}/edit`,
    get breadcrumb() { return t('common/edit'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.FarmSizeEdit),
  },

  'farm-size/list': {
    path: '/farm_size_categories',
    link: (id) => '/farm_size_categories',
    get breadcrumb() { return t('route/farm-size'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.FarmSizeList),
  },

  'farm-size/profile': {
    path: '/farm_size_categories/:id',
    link: (ID) => `/farm_size_categories/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const farmSizeName = _.get(state.farmSize.get('current'), 'name');
      return farmSizeName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/farm_size_categories')),
  },

  //-----------------------------------------
  //	Equipment Group
  //-----------------------------------------

  'equipment-group/create': {
    path: '/product_classification/add_new_equipment_group',
    link: () => '/product_classification/add_new_equipment_group',
    get breadcrumb() { return t('equipment-group/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.EquipmentGroupCreate),
  },

  'equipment-group/edit': {
    path: '/product_classification/equipment_group/:id/edit',
    link: (id) => `/product_classification/equipment_group/${id}/edit`,
    breadcrumb: 'Edit Equipment Group',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.EquipmentGroupEdit),
  },

  'equipment-group/profile': {
    path: '/product_classification/equipment_group/:id',
    link: (ID) => `/product_classification/equipment_group/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const equipmentGroupName = _.get(state.equipmentGroup.get('current'), 'en.name');
      return equipmentGroupName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Size parameter
  //-----------------------------------------

  sizeParametersEdit: {
    path: '/size_parameters/:id/edit',
    link: (id) => `/size_parameters/${id}/edit`,
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.SizeParameterEdit),
    get breadcrumb() {
      return t('sizeParameters/edit/breadcrumbs');
    },
  },

  sizeParametersCreate: {
    path: '/size_parameters/add_new_size_parameter',
    link: () => '/size_parameters/add_new_size_parameter',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.SizeParameterCreate),
    get breadcrumb() {
      return t('sizeParameters/create/breadcrumb');
    },
  },

  sizeParametersProfile: {
    path: '/size_parameters/:id',
    link: (ID) => `/size_parameters/${ID}`,
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => state.sizeParameters.get('item').name),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/size_parameters')),
  },


  sizeParametersList: {
    path: '/size_parameters',
    link: () => '/size_parameters',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.SizeParameterList),
    get breadcrumb() {
      return t('sizeParameters/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Composite Level
  //-----------------------------------------

  'composite-level/create': {
    path: '/product_classification/add_new_сomposite_level',
    link: () => '/product_classification/add_new_сomposite_level',
    get breadcrumb() { return t('composite-level/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CompositeLevelCreate),
  },

  'composite-level/edit': {
    path: '/product_classification/composite_level/:id/edit',
    link: (id) => `/product_classification/composite_level/${id}/edit`,
    breadcrumb: 'Edit Composite Level',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CompositeLevelEdit),
  },

  'composite-level/profile': {
    path: '/product_classification/composite_level/:id',
    link: (ID) => `/product_classification/composite_level/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const compositeLevelName = _.get(state.compositeLevel.get('current'), 'en.name');
      return compositeLevelName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Estimate Description
  //-----------------------------------------

  'estimate-description/create': {
    path: '/product_classification/add_new_estimate_description',
    link: () => '/product_classification/add_new_estimate_description',
    get breadcrumb() { return t('estimate-description/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.EstimateDescriptionCreate),
  },

  'estimate-description/edit': {
    path: '/product_classification/estimate_description/:id/edit',
    link: (id) => `/product_classification/estimate_description/${id}/edit`,
    breadcrumb: 'Edit Estimate Description',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.EstimateDescriptionEdit),
  },

  'estimate-description/profile': {
    path: '/product_classification/estimate_description/:id',
    link: (ID) => `/product_classification/estimate_description/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const estimateDescriptionName = _.get(state.estimateDescription.get('current'), 'en.name');
      return estimateDescriptionName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Model Description
  //-----------------------------------------

  'model-description/create': {
    path: '/product_classification/add_new_model_description',
    link: () => '/product_classification/add_new_model_description',
    get breadcrumb() { return t('model-description/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ModelDescriptionCreate),
  },

  'model-description/edit': {
    path: '/product_classification/model_description/:id/edit',
    link: (id) => `/product_classification/model_description/${id}/edit`,
    breadcrumb: 'Edit Model Description',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.ModelDescriptionEdit),
  },

  'model-description/profile': {
    path: '/product_classification/model_description/:id',
    link: (ID) => `/product_classification/model_description/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const modelDescriptionName = _.get(state.modelDescription.get('current'), 'en.name');
      return modelDescriptionName;
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Base Machinery Description
  //-----------------------------------------

  'base-machinery-description/create': {
    path: '/product_classification/add_new_base_machinery_description',
    link: () => '/product_classification/add_new_base_machinery_description',
    get breadcrumb() { return t('base-machinery-description/create/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.BaseMachineryDescriptionCreate),
  },

  'base-machinery-description/edit': {
    path: '/product_classification/base_machinery_description/:id/edit',
    link: (id) => `/product_classification/base_machinery_description/${id}/edit`,
    get breadcrumb() { return t('base-machinery-description/edit/breadcrumb'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.BaseMachineryDescriptionEdit),
  },

  'base-machinery-description/profile': {
    path: '/product_classification/base_machinery_description/:id',
    link: (ID) => `/product_classification/base_machinery_description/${ID}`,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      return _.get(state.baseMachineryDescription.get('current'), 'en.name');
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(Redirector('/product_classification')),
  },

  //-----------------------------------------
  //	Co Worker
  //-----------------------------------------

  'co-worker/create': {
    path: '/employees/:dealer_id-:name/add_new_co-worker',
    link: ({ id, name }) => `/employees/${id}-${name}/add_new_co-worker`.replace(/\s/g, '_'),
    get breadcrumb() { return t('coWorker/create/bredcrumb'); },
    layout: BaseLayoutRoute,
    component: isAuth(route.CoWorkerCreate),
  },

  'co-worker/profile': {
    path: '/employees/top_manager/:id-:name',
    link: ({
      id, dealer_id, dealer_name, name,
    }) => `/employees/top_manager/${id}-${name}`.replace(/\s/g, '_'),
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const coWorkerName = `${state.coWorker.get('current').last_name} ${state.coWorker.get('current').name}`;
      return coWorkerName;
    }),
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER
    ])(route.CoWorkerProfile),
  },

  'co-worker/role': {
    path: '/employees/co_worker',
    link: () => '/employees/co_worker',
    exact: true,
    breadcrumb: null,
    layout: BaseLayoutRoute,
    component: isAuth(route.CoWorkerEdit),
  },

  'co-worker/edit': {
    path: '/employees/co_worker/:dealer_id-:dealer_name/:id-:co_worker_name/edit',
    link: ({
      id, dealer_id, dealer_name, co_worker_name,
    }) => `/employees/co_worker/${dealer_id}-${dealer_name}/${id}-${co_worker_name}/edit`.replace(/\s/g, '_'),
    exact: true,
    get breadcrumb() { return t('coWorker/edit/title'); },
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.OUTLET_MANAGER,
      accessRoles.SALES_MANAGER
    ])(route.CoWorkerEdit),
  },

  //-----------------------------------------
  //	Staff
  //-----------------------------------------

  staff: {
    path: '/employees',
    link: () => '/employees',
    exact: true,
    layout: BaseLayoutRoute,
    component: isAuth(route.StaffList),
    get breadcrumb() {
      return t('staff/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Country Manager
  //-----------------------------------------

  'country-manager/role': {
    path: '/employees/country_manager',
    link: () => '/employees/country_manager',
    exact: true,
    breadcrumb: null,
    layout: BaseLayoutRoute,
    component: isAuth(route.CountryManagerProfile),
  },

  'country-manager/profile': {
    path: '/employees/country_manager/:id',
    link: ({ id }) => `/employees/country_manager/${id}`,
    exact: true,
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const countryManagerName = state.countryManager.get('current').name;
      return countryManagerName;
    }),
    layout: BaseLayoutRoute,
    component: isAuth(route.CountryManagerProfile),
  },

  //-----------------------------------------
  //	Test
  //-----------------------------------------

  'testCRUD/create': {
    path: '/test/create',
    link: () => '/test/create',
    layout: BaseLayoutRoute,
    component: isAuth(route.Test),
    get breadcrumb() {
      return t('test/list/breadcrumbs');
    },
  },

  'testCRUD/edit': {
    path: '/test/:id/edit',
    link: ({ id }) => `/test/${id}/edit`,
    layout: BaseLayoutRoute,
    component: isAuth(route.TestUpdate),
    get breadcrumb() {
      return t('test/update/breadcrumbs');
    },
  },

  'testCRUD/list': {
    path: '/test',
    link: () => '/test',
    layout: BaseLayoutRoute,
    component: isAuth(route.TestList),
    get breadcrumb() {
      return t('test/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Currency
  //-----------------------------------------

  'currencies/create': {
    path: '/currencies/add_new_currency',
    link: () => '/currencies/add_new_currency',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CurrencyCreate),
    get breadcrumb() {
      return t('currencies/create/breadcrumbs');
    },
  },

  'currencies/edit': {
    path: '/currencies/:id/edit',
    link: ({ id }) => `/currencies/${id}/edit`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.CurrencyUpdate),
    get breadcrumb() {
      return t('currencies/update/breadcrumbs');
    },
  },

  'currencies/profile': {
    path: '/currencies/:id',
    link: ({ id }) => `/currencies/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.CurrencyUpdate),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const currencyName = state.scope.getIn(['info', 'currencies']).name;
      return currencyName;
    }),
  },

  'currencies/list': {
    path: '/currencies',
    link: () => '/currencies',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.CurrencyList),
    get breadcrumb() {
      return t('currencies/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Deal Status
  //-----------------------------------------

  'dealStatuses/edit': {
    path: '/deal-statuses/:id/edit',
    link: ({ id }) => `/deal-statuses/${id}/edit`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealStatusUpdate),
    get breadcrumb() {
      return t('deal-statuses/update/breadcrumbs');
    },
  },

  'dealStatuses/profile': {
    path: '/deal-statuses/:id',
    link: ({ id }) => `/deal-statuses/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.DealStatusUpdate),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const dealStatusName = state.scope.getIn(['info', 'dealStatuses']).en.name;
      return dealStatusName;
    }),
  },

  'dealStatuses/list': {
    path: '/deal-statuses',
    link: () => '/deal-statuses',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TOP_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.PSS
    ])(route.DealStatusList),
    get breadcrumb() {
      return t('deal-statuses/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Deal Actions
  //-----------------------------------------

  'dealActions/create': {
    path: '/next-actions/add_new_next_action',
    link: () => '/next-actions/add_new_next_action',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealActionsCreate),
    get breadcrumb() {
      return t('deal-actions/create/breadcrumbs');
    },
  },

  'dealActions/edit': {
    path: '/next-actions/:id/edit',
    link: ({ id }) => `/next-actions/${id}/edit`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealActionsUpdate),
    get breadcrumb() {
      return t('deal-actions/update/breadcrumbs');
    },
  },

  'dealActions/profile': {
    path: '/next-actions/:id',
    link: ({ id }) => `/next-actions/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.DealActionsUpdate),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const dealActionName = state.scope.getIn(['info', 'dealActions']).en.name;
      return dealActionName;
    }),
  },

  'dealActions/list': {
    path: '/next-actions',
    link: () => '/next-actions',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.PSS
    ])(route.DealActionsList),
    get breadcrumb() {
      return t('deal-actions/list/breadcrumbs');
    },
  },

  'deals/current': {
    path: '/deals/:id',
    link: ({ id }) => `/deals/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.DealCurrent),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const { customer_name: customerName, id } = state.deal.get('current');
      return `${t('deal/profile/prefix')} ${id} ${customerName}`;
    }),
  },

  //-----------------------------------------
  //	Deal Results
  //-----------------------------------------

  // 'dealResults/create': {
  //     path: `/deal-results/create`,
  //     link: () => `/deal-results/create`,
  //     layout: BaseLayoutRoute,
  //     component: isAuth(route.DealResultsCreate),
  //     get breadcrumb() {
  //         return t('deal-results/create/breadcrumbs');
  //     }
  // },

  'dealResults/edit': {
    path: '/deal-results/:id/edit',
    link: ({ id }) => `/deal-results/${id}/edit`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealResultsUpdate),
    get breadcrumb() {
      return t('deal-results/update/breadcrumbs');
    },
  },

  'dealResults/profile': {
    path: '/deal-results/:id',
    link: ({ id }) => `/deal-results/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.DealResultsUpdate),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const dealActionName = state.scope.getIn(['info', 'dealResults']).en.name;
      return dealActionName;
    }),
  },

  'dealResults/list': {
    path: '/deal-results',
    link: () => '/deal-results',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.PSS
    ])(route.DealResultsList),
    get breadcrumb() {
      return t('deal-results/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Deal Lost Reasons
  //-----------------------------------------

  'dealLostReasons/create': {
    path: '/deal-lost-reasons/add_new_lost-reason',
    link: () => '/deal-lost-reasons/add_new_lost-reason',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealLostReasonsCreate),
    get breadcrumb() {
      return t('deal-lost-reasons/create/breadcrumbs');
    },
  },

  'dealLostReasons/edit': {
    path: '/deal-lost-reasons/:id/edit',
    link: ({ id }) => `/deal-lost-reasons/${id}/edit`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER
    ])(route.DealLostReasonsUpdate),
    get breadcrumb() {
      return t('deal-lost-reasons/update/breadcrumbs');
    },
  },

  'dealLostReasons/profile': {
    path: '/deal-lost-reasons/:id',
    link: ({ id }) => `/deal-lost-reasons/${id}`,
    layout: BaseLayoutRoute,
    component: isAuth(route.DealLostReasonsUpdate),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      const dealActionName = state.scope.getIn(['info', 'dealLostReasons']).en.name;
      return dealActionName;
    }),
  },

  'dealLostReasons/list': {
    path: '/deal-lost-reasons',
    link: () => '/deal-lost-reasons',
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.COUNTRY_MANAGER,
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COMPANY,
      accessRoles.TOP_MANAGER,
      accessRoles.PSS
    ])(route.DealLostReasonsList),
    get breadcrumb() {
      return t('deal-lost-reasons/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	BOED
  //-----------------------------------------

  // 'BOED/list': {
  //   path: '/BOED',
  //   link: () => '/BOED',
  //   layout: BaseLayoutRoute,
  //   component: isAuth(route.BOEDList),
  //   get breadcrumb() {
  //     return t('BOED/list/breadcrumbs');
  //   },
  // },

	//-----------------------------------------
	//	BOED_v2
	//-----------------------------------------

	'BOED/list_v2': {
		path: '/BOED',
		link: () => '/BOED',
		layout: BaseLayoutRoute,
		component: isAuth(route.BOEDList_v2),
		get breadcrumb() {
			return t('BOED/list/breadcrumbs');
		},
	},

  //-----------------------------------------
  //	BOED
  //-----------------------------------------

  'deals/list': {
    path: '/deals',
    link: () => '/deals',
    layout: BaseLayoutRoute,
    component: isAuth(route.DealList),
    get breadcrumb() {
      return t('deals/list/breadcrumbs');
    },
  },

  'deals/vehicle/edit': {
    path: '/vehicles/:deal_id/:id',
    link: ({id, deal_id}) => `/vehicles/${deal_id}/${id}`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.TERRITORY_MANAGER,
	  accessRoles.COUNTRY_MANAGER,
	])(route.DealVehicleEdit),
    breadcrumb: buildSpecialBreadcrumb(() => {
    	return t('deals/vehicle/edit/breadcrumbs');
    })
  },

  'deals/trade_in_vehicle/edit': {
    path: '/trade-in/:deal_id/client/:client_id/vehicles/:id',
    link: ({id, client_id, deal_id}) => `trade-in/${deal_id}/client/${client_id}/vehicles/${id}`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COUNTRY_MANAGER
    ])(route.TradeInVehicleEdit),
    breadcrumb: buildSpecialBreadcrumb(() => {
      return t('deals/trade_in/vehicle/edit/breadcrumbs');
    })
  },

  'deals/trade_in_vehicle/create': {
    path: '/trade-in/:deal_id/client/:client_id/create',
    link: ({deal_id, client_id}) => `trade-in/${deal_id}/client/${client_id}/create`,
    layout: BaseLayoutRoute,
    component: accessPermission([
      accessRoles.TERRITORY_MANAGER,
      accessRoles.COUNTRY_MANAGER
    ])(route.TradeInVehicleCreate),
    breadcrumb: buildSpecialBreadcrumb(() => {
      return t('deals/trade_in/vehicle/create/breadcrumbs');
    })
  },

  //-----------------------------------------
  //	Translate
  //-----------------------------------------

  'translate/list': {
    path: '/translates',
    link: () => '/translates',
    layout: BaseLayoutRoute,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.TranslateModule),
    get breadcrumb() {
      return t('translates/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Notification-emails
  //-----------------------------------------

  notificationEmails: {
    path: '/notification-emails',
    link: () => '/notification-emails',
    layout: BaseLayoutRoute,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.NotificationEmails),
    get breadcrumb() {
      return t('notification-emails/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Client vehicle Import
  //-----------------------------------------

  clientVehicleImport: {
    path: '/vehicle-import',
    link: () => '/vehicle-import',
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.ClientVehicleImport),
    get breadcrumb() {
      return t('vehicle-import/list/breadcrumbs');
    },
  },

  clientVehicleImportView: {
    path: '/vehicle-import/:id',
    link: (id) => `/vehicle-import/${id}`,
    layout: BaseLayoutRoute,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.ClientVehicleImportView),
    breadcrumb: buildSpecialBreadcrumb((state) => {
      return state.vehicleImport.get('currentImport').id;
    }),
  },

	//-----------------------------------------
	//	Ios links Import
	//-----------------------------------------

  iosLinksImport: {
    path: '/ios-links-import',
    link: () => '/ios-links-import',
    layout: BaseLayoutRoute,
    exact: true,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.IosLinksImport),
    get breadcrumb() {
        return t('ios-links-import/list/breadcrumbs');
    },
  },

  iosLinksImportView: {
    path: '/ios-links-import/:id',
    link: (id) => `/ios-links-import/${id}`,
    layout: BaseLayoutRoute,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.IosLinksImportView),
    breadcrumb: buildSpecialBreadcrumb((state) => {
        return state.iosLinksImport.get('currentImport').id;
    }),
  },

  //-----------------------------------------
  //	Deals lost to companies
  //-----------------------------------------

  lostToCompanies: {
    path: '/lost-to-companies',
    link: `/lost-to-companies`,
    layout: BaseLayoutRoute,
    component: accessPermission([
        accessRoles.COUNTRY_MANAGER
    ])(route.lostToCompanies),
    get breadcrumb() {
      return t('lost-to-companies/list/breadcrumbs');
    },
  },

  //-----------------------------------------
  //	Sales manager efficiency
  //-----------------------------------------

  // salesEfficiency: {
  //   path: '/sales-efficiency',
  //   link: `/sales-efficiency`,
  //   layout: BaseLayoutRoute,
  //   component: isAuth(route.salesEfficiency),
  //   get breadcrumb() {
  //     return t('sales-efficiency/list/breadcrumbs');
  //   },
  // },

	//-----------------------------------------
	//	Sales manager efficiency v2
	//-----------------------------------------

	salesEfficiency_v2: {
		path: '/sales-efficiency',
		link: `/sales-efficiency`,
		layout: BaseLayoutRoute,
		component: isAuth(route.salesEfficiency_v2),
		get breadcrumb() {
			return t('sales-efficiency/list/breadcrumbs');
		},
	},

  //-----------------------------------------
  //	brand loyalty
  //-----------------------------------------

  // analytics: {
  //   path: '/analytics',
  //   link: '/analytics',
  //   layout: BaseLayoutRoute,
  //   exact: true,
  //   component: isAuth(route.Analytics),
  //   get breadcrumb() {
  //     return t('analytics/list/breadcrumbs');
  //   },
  // },

	//-----------------------------------------
	//	brand loyalty v2
	//-----------------------------------------

	analytics_v2: {
		path: '/analytics',
		link: '/analytics',
		layout: BaseLayoutRoute,
		exact: true,
		component: isAuth(route.Analytics_v2),
		get breadcrumb() {
			return t('analytics/list/breadcrumbs');
		},
	},

  //-----------------------------------------
  //	Client vehicle Import
  //-----------------------------------------

  // productsQuantity: {
  //   path: '/products-quantity',
  //   link: '/products-quantity',
  //   layout: BaseLayoutRoute,
  //   exact: true,
  //   component: isAuth(route.ProductsQuantity),
  //   get breadcrumb() {
  //     return t('products-quantity/list/breadcrumbs');
  //   },
  // },

	//-----------------------------------------
	//	Client vehicle Import v2
	//-----------------------------------------

	productsQuantity_v2: {
		path: '/products-quantity',
		link: '/products-quantity',
		layout: BaseLayoutRoute,
		exact: true,
		component: isAuth(route.ProductsQuantity_v2),
		get breadcrumb() {
			return t('products-quantity/list/breadcrumbs');
		},
	},

	//-----------------------------------------
	//	Maps
	//-----------------------------------------

	maps: {
		path: '/maps',
		link: '/maps',
		layout: BaseLayoutRoute,
		exact: true,
		component: isAuth(route.Maps),
		get breadcrumb() {
			return t('maps/breadcrumbs');
		},
	},

	//-----------------------------------------
	//	Actual sales plan import
	//-----------------------------------------

	actualSalesPlanImport: {
		path: '/actual-sales-plan-import',
		link: () => '/actual-sales-plan-import',
		layout: BaseLayoutRoute,
		exact: true,
		component: accessPermission([
        	accessRoles.COUNTRY_MANAGER
    	])(route.ActualSalesPlanImport),
		get breadcrumb() {
			return t('actual-sales-plan-import/list/breadcrumbs');
		},
	},

	actualSalesPlanImportView: {
		path: '/actual-sales-plan-import/:id',
		link: (id) => `/actual-sales-plan-import/${id}`,
		layout: BaseLayoutRoute,
		component: accessPermission([
        	accessRoles.COUNTRY_MANAGER
    	])(route.ActualSalesPlanImportView),
		breadcrumb: buildSpecialBreadcrumb((state) => {
			return state.actualSalesPlanImport.get('currentImport').id;
		}),
	},

	//-----------------------------------------
	//	Actual sales plan draft
	//-----------------------------------------

	actualSalesPlanDraft: {
		path: '/actual-sales-plan-draft',
		link: () => '/actual-sales-plan-draft',
		layout: BaseLayoutRoute,
		exact: true,
		component: accessPermission(canSeePlanFactRoles)(route.ActualSalesPlanDraft),
		get breadcrumb() {
			return t('actual-sales-plan-draft/list/breadcrumbs');
		},
	},

	actualSalesPlanDraftEdit: {
		path: '/actual-sales-plan-draft/:id',
		link: (id) => `/actual-sales-plan-draft/${id}`,
		layout: BaseLayoutRoute,
		component: accessPermission(canSeePlanFactRoles)(route.actualSalesPlanDraftEdit),
		breadcrumb: buildSpecialBreadcrumb((state) => {
			return state.actualSalesPlanDraft.get('current').description_product_name;
		}),
	},

	//-----------------------------------------
	//	Actual sales plans
	//-----------------------------------------

	actualSalesPlans: {
		path: '/actual-sales-plans',
		link: () => '/actual-sales-plans',
		layout: BaseLayoutRoute,
		exact: true,
		component: accessPermission(canSeePlanFactRoles)(route.ActualSalesPlans),
		get breadcrumb() {
			return t('actual-sales-plans/list/breadcrumbs');
		},
	},

	// actualSalesPlansEdit: {
	// 	path: '/actual-sales-plans/:id',
	// 	link: (id) => `/actual-sales-plans/${id}`,
	// 	layout: BaseLayoutRoute,
	// 	component: accessPermission(canSeePlanFactRoles)(route.actualSalesPlansEdit),
	// 	breadcrumb: buildSpecialBreadcrumb((state) => {
	// 		return state.actualSalesPlans.get('current').id;
	// 	}),
	// },

	//-----------------------------------------
	//	Actual sales plan maps
	//-----------------------------------------

	actualSalesPlanMaps: {
		path: '/actual-sales-plan-maps',
		link: '/actual-sales-plan-maps',
		layout: BaseLayoutRoute,
		exact: true,
		component: isAuth(route.ActualSalesPlanMaps),
		get breadcrumb() {
			return t('actual-sales-plan-maps/breadcrumbs');
		},
	},

	//-----------------------------------------
	//	Actual sales plan deal maps
	//-----------------------------------------

	actualSalesPlanDealMaps: {
		path: '/actual-sales-plan-deal-maps',
		link: '/actual-sales-plan-deal-maps',
		layout: BaseLayoutRoute,
		exact: true,
		component: isAuth(route.ActualSalesPlanDealMaps),
		get breadcrumb() {
			return t('actual-sales-plan-deal-maps/breadcrumbs');
		},
	},

	//-----------------------------------------
	//	Actual sales plans
	//-----------------------------------------

	actualSalesPlansSummary: {
		path: '/actual-sales-plans-summary',
		link: () => '/actual-sales-plans-summary',
		layout: BaseLayoutRoute,
		exact: true,
		component: accessPermission(canSeePlanFactRoles)(route.ActualSalesPlansSummary),
		get breadcrumb() {
			return t('actual-sales-plans-summary/list/breadcrumbs');
		},
	},

	//-----------------------------------------
	//	Service codes
	//-----------------------------------------

	serviceCodes: {
		path: '/service-codes',
		link: () => '/service-codes',
		exact: true,
		layout: BaseLayoutRoute,
		component: accessPermission(canSeePlanFactRoles)(route.ServiceCodesList),
		get breadcrumb() {
			return t('service-codes/list/breadcrumbs');
		},
	},

	serviceCodesCreate: {
		path: '/service-codes/create',
		link: () => `/service-codes/create`,
		layout: BaseLayoutRoute,
		component: accessPermission(canUpdateServiceCodesRoles)(route.ServiceCodesCreate),
		get breadcrumb() {
			return t('service-codes/create/breadcrumbs');
		},
	},

	serviceCodesEdit: {
		path: '/service-codes/:id',
		link: (id) => `/service-codes/${id}`,
		layout: BaseLayoutRoute,
		component: accessPermission(canUpdateServiceCodesRoles)(route.ServiceCodesEdit),
		breadcrumb: buildSpecialBreadcrumb((state) => {
			return state.serviceCodes.get('current').id;
		}),
	},

  //-----------------------------------------
  //	Others
  //-----------------------------------------

  forbidden: {
    path: '/forbidden',
    layout: BaseLayoutRoute,
    component: isAuth(route.Forbidden),
  },

  'logged-out': {
    path: '/logged-out',
    layout: EmptyLayoutRoute,
    component: isGuest(route.LoggedOut),
  },

  'not-found': {
    path: null,
    layout: BaseLayoutRoute,
    component: isAuth(route.PageNotFound),
  },
};

export default routes;
