import SelectDealerForCoWorker from '@/components/common/modal/SelectDealerForCoWorker';
import AvailableManagers from '@/components/deals/modals/AvailableManagers';
import AvailableClients from '@/components/deals/modals/AvailableClients';
import AvailableStatuses from '@/components/deals/modals/AvailableStatuses';
import CreateNotificationEmail from '@/components/notification-emails/modals/CreateNotificationEmail';
import CreateCompany from '@/components/lost-to-companies/modals/CreateCompany';
import ChangeCredentialsModal from '@/components/change-credentials';
import ChangeFiscalYear from '@/components/deals/modals/FiscalYear';
import TransferDeals from '@/components/employees/modals/TransferDeals';
import EditActualSalesPlan from '@/components/actual-sales-plans/modals/EditActualSalesPlan';
import EditClientLocations from '@/components/client/modals/EditClientLocations';

export const MODALS = {
	SelectDealerForCoWorker,
	AvailableManagers,
	AvailableClients,
	CreateNotificationEmail,
	CreateCompany,
	ChangeCredentialsModal,
	AvailableStatuses,
	ChangeFiscalYear,
	TransferDeals,
	EditActualSalesPlan,
	EditClientLocations
};
