import moment from 'moment';
import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '@/redux/helpers';
import { apiNumberFormatter } from '@/utilities/formatter';
import { boedChartConfig } from '@/components/BOED_v2/charts';
import t from '@/utilities/translate';

const initialState = new Map({
	list: [],
	chart: [],
	chartRightLabels: [],
	filter: {},
	display: undefined,
});

export default createReducer(initialState, {
	[types.SET_BOED_LIST_V2]: (state, { payload }) => state.set('list', (() => {
		const calculations = (item) => {
			const {
				estimateDescription,
				coverageGoal,
				marketShareGoal,
				unitSalesGoal,
				hotProspects,
				salesProject,
				offersNotMade,
				offersMade,
				offersMadeOfferedPrice,
				won,
				downPayment,
				fullPayment,
				lost,
				hotProspectsDeals = [],
				salesProjectDeals = [],
				offersNotMadeDeals = [],
				offersMadeDeals = [],
				wonDeals = [],
				fullPaymentDeals = [],
				downPaymentDeals = [],
				lostDeals = []
			} = item;

			const _industryForecast = Math.ceil(unitSalesGoal / (marketShareGoal / 100)) || 0;
			const coverageGoalUnits = Math.ceil(_industryForecast * coverageGoal / 100);
			const _marketCoverage = ((hotProspects / _industryForecast) * 100).toFixed(2);
			const marketCoverage = isFinite(_marketCoverage) && !isNaN(_marketCoverage) ? _marketCoverage : 0;
			const _salesCoverage = ((salesProject / _industryForecast) * 100).toFixed(2);
			const salesCoverage = isFinite(_salesCoverage) && !isNaN(_salesCoverage) ? _salesCoverage : 0;
			const _notQuotingCoverage = ((offersNotMade / _industryForecast) * 100).toFixed(2);
			const notQuotingCoverage = isFinite(_notQuotingCoverage) && !isNaN(_notQuotingCoverage) ? _notQuotingCoverage : 0;
			const _quotingCoverage = ((offersMade / _industryForecast) * 100).toFixed(2);
			const quotingCoverage = isFinite(_quotingCoverage) && !isNaN(_quotingCoverage) ? _quotingCoverage : 0;
			const _salesParticipation = (((won + lost) / _industryForecast) * 100).toFixed(2);
			const salesParticipation = isFinite(_salesParticipation) && !isNaN(_salesParticipation) ? _salesParticipation : 0;
			const missingSalesToGoal = Math.ceil(unitSalesGoal - fullPayment);
			const __divisionOp = Math.ceil(won + lost);
			const closureRate = __divisionOp === 0 ? won : ((won / __divisionOp) * 100).toFixed(2); // -> 0 / 0 = NaN | 1 / 0 = Infinity
			const _marketShareEst = (((salesParticipation / 100) * (closureRate / 100)) * 100).toFixed(2);
			const marketShareEst = isFinite(_marketShareEst) && !isNaN(_marketShareEst) ? _marketShareEst : 0;

			let values = apiNumberFormatter({
				estimateDescription,
				unitSalesGoal,
				marketShareGoal,
				industryForecast: _industryForecast,
				coverageGoal,
				coverageGoalUnits,
				hotProspects,
				marketCoverage,
				totalSalesProjects: salesProject,
				salesCoverage,
				offersNotMade,
				notQuotingCoverage,
				offersMade,
				offersMadeOfferedPrice,
				quotingCoverage,
				// won,
				downPayment,
				fullPayment,
				lost,
				salesParticipation,
				missingSalesToGoal,
				closureRate,
				marketShareEst
			});

			values = {
				...values,
				hotProspects: { value: values.hotProspects, deals: hotProspectsDeals },
				totalSalesProjects: { value: values.totalSalesProjects, deals: salesProjectDeals },
				offersNotMade: { value: values.offersNotMade, deals: offersNotMadeDeals },
				offersMade: { value: values.offersMade, deals: offersMadeDeals },
				// won: { value: values.hotProspects, deals: wonDeals },
				fullPayment: { value: values.fullPayment, deals: fullPaymentDeals },
				downPayment: { value: values.downPayment, deals: downPaymentDeals },
				lost: { value: values.lost, deals: lostDeals },
				coverageGoal: !isNaN(coverageGoal) ? values.coverageGoal + ' %' : values.coverageGoal,
				marketShareGoal: !isNaN(marketShareGoal) ? values.marketShareGoal + ' %' : values.marketShareGoal,
				marketCoverage: !isNaN(marketCoverage) ? values.marketCoverage + ' %' : values.marketCoverage,
				salesCoverage: !isNaN(salesCoverage) ? values.salesCoverage + '%' : values.salesCoverage,
				notQuotingCoverage: !isNaN(notQuotingCoverage) ? values.notQuotingCoverage + ' %' : values.notQuotingCoverage,
				quotingCoverage: !isNaN(quotingCoverage) ? values.quotingCoverage + ' %' : values.quotingCoverage,
				salesParticipation: !isNaN(salesParticipation) ? values.salesParticipation + ' %' : values.salesParticipation,
				closureRate: !isNaN(closureRate) ? values.closureRate + ' %' : values.closureRate,
				marketShareEst: !isNaN(marketShareEst) ? values.marketShareEst + ' %' : values.marketShareEst
			}

			return values;
		};
		return payload.table.map(calculations);
	})())
		.set('updateTime', moment().format('DD MMM YYYY HH:mm'))
		.set('chart', (() => {
			const {
				chart
			} = payload;

			const {
				marketShareGoal,
				unitSalesGoal,
				coverageGoal
			} = chart;

			const _industryForecast = Math.ceil(unitSalesGoal / (marketShareGoal / 100));
			const coverageGoalUnits = Math.ceil(_industryForecast * coverageGoal / 100);

			const _chart = {
				...chart
			};

			if (!isNaN(_industryForecast)) {
				_chart.industryForecast = _industryForecast
			}

			if (!isNaN(coverageGoalUnits)) {
				_chart.coverageGoal = coverageGoalUnits
			}

			const data = [];

			const chartOrder = [
				'unitSalesGoal',
				'industryForecast',
				'coverageGoal',
				'hotProspects',
				'salesProject',
				'offersMade',
				'won',
				'lost',
				'empty'
			];

			chartOrder.forEach((item) => {
				if (item !== 'estimateDescription' && boedChartConfig.boed_chart_colors[item]) {
					data.push({
						name: item === 'coverageGoal' ? t(`BOED/list/coverageGoalUnits`) : t(`BOED/list/${item}`),
						value: [-_chart[item], _chart[item]],
						fill: boedChartConfig.boed_chart_colors[item]
					});
				} else if (item === 'empty') {
					data.push({
						name: '',
						value: [null, null],
						fill: 'rgb(255, 255, 255)'
					});
				}
			});
			// data.sort((a, b) => a.value > b.value ? -1 : 1);
			return data;
		})())
		.set('chartRightLabels', (() => {
			const {
				chart
			} = payload;

			const {
				marketShareGoal,
				coverageGoal
			} = chart;

			const _industryForecast = Math.ceil(chart.unitSalesGoal / (marketShareGoal / 100));
			const marketCoverage = ((chart.hotProspects / _industryForecast) * 100).toFixed(2); // ((hotProspects / _industryForecast) * 100).toFixed(2)
			const salesCoverage = ((chart.salesProject / _industryForecast) * 100).toFixed(2);
			// const notQuotingCoverage =  ((chart.offersNotMade / _industryForecast) * 100).toFixed(2);
			const quotingCoverage = ((chart.offersMade / _industryForecast) * 100).toFixed(2);
			const salesParticipation = (((chart.won + chart.lost) / _industryForecast) * 100).toFixed(2);
			const __divisionOp = Math.ceil(chart.won + chart.lost);
			const closureRate = __divisionOp === 0 ? chart.won : ((chart.won / __divisionOp) * 100).toFixed(2); // -> 0 / 0 = NaN | 1 / 0 = Infinity
			const marketShareEst = (((salesParticipation / 100) * (closureRate / 100)) * 100).toFixed(2);

			const data = [];
			const values = {
				marketShareGoal: !isNaN(marketShareGoal) ? marketShareGoal + ' %' : marketShareGoal,
				empty1: 'empty',
				coverageGoal: !isNaN(coverageGoal) ? coverageGoal + ' %' : coverageGoal,
				marketCoverage: !isNaN(marketCoverage) ? marketCoverage + ' %' : marketCoverage,
				salesCoverage: !isNaN(salesCoverage) ? salesCoverage + '%' : salesCoverage,
				// notQuotingCoverage: !isNaN(notQuotingCoverage) ? notQuotingCoverage + ' %' : notQuotingCoverage,
				quotingCoverage: !isNaN(quotingCoverage) ? quotingCoverage + ' %' : quotingCoverage,
				closureRate: !isNaN(closureRate) ? closureRate + ' %' : closureRate,
				salesParticipation: !isNaN(salesParticipation) ? salesParticipation + ' %' : salesParticipation,
				marketShareEst: !isNaN(marketShareEst) ? marketShareEst + ' %' : marketShareEst
			};

			Object.keys(values).forEach((item) => {
				if (values[item] !== 'NaN') {
					if (values[item] === 'empty') {
						data.push({
							name: '',
							value: ''
						})
					} else {
						data.push({
							name: t(`BOED/list/${item}`),
							value: values[item]
						})
					}
				}
			});

			return data;
		})()),
	[types.SET_BOED_DISPLAY_V2]: (state, { payload }) => state.set('display', payload),
	[types.SET_FILTER_VALUE_V2]: (state, { payload }) => state.set('filter', {
		countryId: payload.country_id ? payload.country_id : null,
		equipmentType: payload.equipment_type ? payload.equipment_type : null,
		fiscalYear: payload.fy_type ? payload.fy_type : null,
		manufacturer: Array.isArray(payload.manufacturer) ? payload.manufacturer[0] : payload.manufacturer,
		dealer: payload.dealer === 'total' ? null : Array.isArray(payload.dealer) ? payload.dealer[0] : payload.dealer,
		manager: payload.manager === 'total' ? null : Array.isArray(payload.manager) ? payload.manager[0] : payload.manager,
		time: payload.date_from || payload.date_to ? [
			moment(payload.date_from),
			moment(payload.date_to)
		] : null
	}),
});
