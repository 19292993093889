import http from '@/services/http';

//-----------------------------------------
// Auth
//-----------------------------------------

export const authApi = {
  login: (data) => http.post('/login', data),
  refreshToken: (refreshToken) => http.post('/refresh-token', { refresh_token: refreshToken }),
  userInfo: () => http.get('/user-info'),
  logout: () => http.post('/logout'),
  refreshTokenPromise: null,
};

//-----------------------------------------
// Change credentials
//-----------------------------------------

export const changeCredentials = {
  coworker: ({ id, ...data}) => http.put(`/coworker/${id}/change-credentials`, data),
  employee: ({ id, ...data}) => http.put(`/employee/${id}/change-credentials`, data),
  pss: ({ id, ...data}) => http.put(`/product-sales-specialist/${id}/change-credentials`, data),
  tm: ({ id, ...data}) => http.put(`/territorial-manager/${id}/change-credentials`, data),
  dealers: ({ id, ...data}) => http.put(`/dealers/${id}/change-credentials`, data),
};

//-----------------------------------------
//	System
//-----------------------------------------

export const systemApi = {
  translate: (lang) => http.get('/translates-get-list'),
  location: (city) => http.post('/location', { query: city }),
  roles: () => http.get('/roles'),
  fermCategories: (query) => http.get('/farm-category', { params: query }),
};

//-----------------------------------------
//	User's settings
//-----------------------------------------

export const usersSettings = {
  getUsersSettings: () => http.get('/users-settings'),
  fetchUsersSetting: (id) => http.get(`/users-settings/${id}`),
  editUsersSetting: ({id, data}) => http.put(`/users-settings/${id}`, data),
};

//-----------------------------------------
//	Employee (lvl 1,2)
//-----------------------------------------

export const employeesApi = {
  userList: (query) => http.get('/employee', { params: query }),
  delas: ({ query }) => http.get('/employee-profile/deals', { params: query }),
  create: (data) => http.post('/employee', data),
  edit: (id, data) => http.put(`/employee/${id}`, data),
  userInfo: (id) => http.get(`/employee/${id}`),
  activate: (id, data) => http.put(`/employee/${id}/change-status`, data),
  changeStatus: (id) => http.put(`/employee/${id}/change-status`),
  changeLoginAndPassword: (id, payload) => http.put(`/employee/${id}/change-password-login`, payload),
  outDeals: ({ id, query }) => http.get(`/employee/${id}/deals`, { params: query }),
  salesMenByDealerId: (id) => http.get('/employee-by-dealer', {
    params: {
      dealer: id,
    },
  }),
  salesMenByQuery: (query) => http.get('/employee-by-query', { params: query }),
  uploadUserPhoto: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    http.post(`/employee/${id}/upload-photo`, fd);
  },
  sendIosLink: (id) => http.put(`/employee/${id}/send-ios-link`),
  changeManager: ({ id, managerId }) => http.put(`/employee/${id}/deals/change-manager`, { sales_id: managerId })
};

//-----------------------------------------
//	Product sales specialist (pss | 7)
//-----------------------------------------

export const productSalesSpecialist = {
  create: (query) => http.post('/product-sales-specialist', query),
  current: (id) => http.get(`/product-sales-specialist/${id}`),
  edit: (id, query) => http.put(`/product-sales-specialist/${id}`, query),
  delete: (id) => http.delete(`/product-sales-specialist/${id}`),
  uploadPhoto: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    http.post(`/product-sales-specialist/${id}/upload-photo`, fd);
  },
  deletePhoto: (id) => http.delete(`/product-sales-specialist/${id}/delete-photo`),
  changeStatus: (id) => http.put(`/product-sales-specialist/${id}/change-status`),
  changePasswordLogin: (id) => http.put(`/product-sales-specialist/${id}/change-password-login`)
};

//-----------------------------------------
//	Manufacture
//-----------------------------------------

export const manufacture = {
  list: (query) => http.get('/manufactures', { params: query }),
  edit: (id, query) => http.put(`/manufactures/${id}`, query),
  create: (query) => http.post('/manufactures', query),
  current: (id) => http.get(`/manufactures/${id}`),
  change_status: (id) => http.put(`/manufactures/${id}/change-status`),
  changeActualSales: (id) => http.put(`/manufactures/${id}/change-actual-sales`),
  manufacture_list: (query) => http.get('/manufactures-list', { params: query }),
};

//-----------------------------------------
//	Trade-in
//-----------------------------------------

export const trade_in = {
  fetchItem: ({ deal_id, deal_trade_in_item }) => http.get(`/deals/${deal_id}/trade-in/${deal_trade_in_item}`),
  edit: ({ deal_id, deal_trade_in_item, ...query }) => http.put(`/deals/${deal_id}/trade-in/${deal_trade_in_item}`, query),
  create: ({ deal_id, ...query }) => http.post(`/deals/${deal_id}/trade-in`, query),
  clientVehiclesList: (query) => http.get(`/client-vehicles-active-list-by-client-id`, {
    params: query
  }),
  delete: ({ deal_id, deal_trade_in_item }) => http.delete(`/deals/${deal_id}/trade-in/${deal_trade_in_item}`)
};

//-----------------------------------------
//	NotificationEmails
//-----------------------------------------

export const notificationEmails = {
  list: (query) => http.get('/user-notifications', { params: query }),
  edit: (id, query) => http.put(`/user-notifications/${id}`, query),
  create: (query) => http.post('/user-notifications', query),
  current: (id) => http.get(`/user-notifications/${id}`),
  change_status: (id) => http.put(`/user-notifications/${id}/change-status`),
  delete: (id) => http.delete(`/user-notifications/${id}`),
  getAvailableUsers: (query) => http.get('/users-by-query', {
    params: {
      query
    }
  })
};

//-----------------------------------------
//	Platform
//-----------------------------------------

export const platform = {
  edit: (id, payload) => http.put(`/platforms/${id}`, payload),
  current: (id) => http.get(`/platforms/${id}`),
  list: () => http.get('/platforms'),
  platform_list_for_eq: () => http.get('/platforms-list'),
  platform_list_by_manufacture: (payload) => {
    return http.get('/get-platforms-list-with-products-by-manufacture', { params: payload });
  },
  create: (query) => http.post('/platforms', query),
  changeStatus: (id) => http.put(`/platforms/${id}/change-status`),
};

//-----------------------------------------
//	Equipment Group
//-----------------------------------------

export const equipment_group = {
  eq_list: () => http.get('/equipment-group-list'),
  edit: (id, payload) => http.put(`/equipment-group/${id}`, payload),
  current: (id) => http.get(`/equipment-group/${id}`),
  create: (query) => http.post('/equipment-group', query),
  change_status: (id) => http.put(`/equipment-group/${id}/change-status`),
  list_by_platform_id: (id) => http.get(`/equipment-group-list/${id}`),
};

//-----------------------------------------
//	Size Class
//-----------------------------------------

export const size_class = {
  list_by_eq_id: (id) => http.get(`/size-class-list/${id}`),
  create: (payload) => http.post('/size-class', payload),
  current: (id) => http.get(`/size-class/${id}`),
  change_status: (id) => http.put(`/size-class/${id}/change-status`),
  edit: (id, payload) => http.put(`/size-class/${id}`, payload),
  size_class_list: () => http.get('/size-class-list'),
};

//-----------------------------------------
//	Product
//-----------------------------------------

export const product = {
  list: (payload) => http.get('/products', { params: payload }),
  change_status: (id) => http.put(`/products/${id}/change-status`),
  edit: (id, payload) => http.put(`/products/${id}`, payload),
  current: (id) => http.get(`/products/${id}`),
  create: (payload) => http.post('/products', payload),
  remove: (id) => http.delete(`/products/${id}`),
  upload_photo: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    http.post(`/products/${id}/upload-photo`, fd);
  },
  listByParams: (params) => {
    return http.get(`/products-list-by-params`, {
      params: params
    });
  },
  currenciesActiveList: (params) => http.get(`/currencies-active-list`)
};

//-----------------------------------------
//	Total count
//-----------------------------------------

export const total_country = {
  marketPotentional: (query) => http.post('/total-market-potentional', query),
  setMarketPotentional: (query) => http.put(`/total-market-potentional/${query.id}`, query),
  dealerBrakedown: (query) => http.post('/dealer-model-breakdown', query),
  setDealerBrakedown: (id, query) => http.put(`/dealer-model-breakdown/${id}`, query),
  AORPotentional: (query) => http.post('/aor-potentional', query),
  setAORPotentional: (id, query) => http.put(`/aor-potentional/${id}`, query),
  salesManPlan: (query) => http.post('/sales-man-plan', query),
  setSalesManPlan: (id, query) => http.put(`/sales-man-plan/${id}`, query),
};

//-----------------------------------------
//	Total count v2
//-----------------------------------------

export const total_country_v2 = {
	marketPotentional_v2: (query) => http.post('/v2/total-market-potentional', query),
	setMarketPotentional_v2: (query) => http.put(`/v2/total-market-potentional/${query.id}`, query),
	dealerBrakedown_v2: (query) => http.post('/v2/dealer-model-breakdown', query),
	setDealerBrakedown_v2: (id, query) => http.put(`/v2/dealer-model-breakdown/${id}`, query),
	AORPotentional_v2: (query) => http.post('/v2/aor-potentional', query),
	setAORPotentional_v2: (id, query) => http.put(`/v2/aor-potentional/${id}`, query),
	salesManPlan_v2: (query) => http.post('/v2/sales-man-plan', query),
	setSalesManPlan_v2: (id, query) => http.put(`/v2/sales-man-plan/${id}`, query),
};

//-----------------------------------------
//	Sales manager
//-----------------------------------------

export const salesManager = {
  listByRole: () => http.get('/sales-managers-list-by-role'),
  listByOutlets: (payload) => http.get('/sales-managers-by-outlets', { params: { outlet: payload } })
};

//-----------------------------------------
//	Outlet manager
//-----------------------------------------

export const outletManager = {
  listByRole: () => http.get('/outlet-managers-list-by-role'),
  listByDealers: (payload) => http.get('/outlets-by-dealers', { params: { dealer: payload } }),
};

//-----------------------------------------
//	Dealer (lvl 3)
//-----------------------------------------

export const dealer = {
  create: (query) => http.post('/dealers', query),
  current: (id) => http.get(`/dealers/${id}`),
  listOfDealers: () => http.get('/dealers-list'),
  changePasswordLogin: (id) => http.put(`/dealers/${id}/change-password-login`),
  changeStatus: (id) => http.put(`/dealers/${id}/change-status`),
  chnageStatus: (id) => http.put(`/dealers/${id}/change-status`),
  edit: (id, payload) => http.put(`/dealers/${id}`, payload),
  list: (payload) => http.get('/dealers', { params: payload }),
  listOfActive: (payload) => {
    if (window._location.includes('estimate_goals')) {
      return http.get('/dealers-active-list', {
        params: {
          estimate: true,
		  ...payload
        }
      });
    }
    return http.get('/dealers-active-list', { params: payload });
  },
  uploadPhoto: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    http.post(`/dealers/${id}/upload-photo`, fd);
  },
};
export const complate = {
  dealer: ({ query }) => http.get(`/dealers-by-query?query=${query}`),
  staff: ({ query }) => http.get(`/staff-by-query?query=${query}`),
  countries: ({ query }) => http.get(`/countries-by-query?query=${query}`),
  regions: ({ query }) => http.get(`/regions-by-query?query=${query}`),
  districts: ({ query }) => http.get(`/districts-by-query?query=${query}`),
  jobs: ({ query }) => http.get(`/jobs-titles-by-query?query=${query}`),
  employee: ({ query }) => http.get(`/employee-by-query?query=${query}`),
  product: ({ query }) => http.get(`/product-by-query?query=${query}`),
  companyLostDeals: ({ query }) => http.get(`/company-lost-deals-list?query=${query}`),
  model_description: (action) => {
    return http.get(`/model-description-active-list-by-manufacture-with-products`, {
      params: {
        ...action
      }
    });
  },
  modelDescriptionsByProductLines: ({ query, ...rest }) => http.get('/model-description-active-list', {
	params: {
	  query,
	  ...rest
	},
  }),
};

export const testCRUD = {
  info: ({ id }) => http.get(`/countries/${id}`),
  update: ({ id, query }) => http.put(`/countries/${id}`, query),
  list: ({ query }) => http.get('/countries', { params: query }),
  changeStatus: ({ id }) => http.put(`/countries/${id}/change-status`),
};

//-----------------------------------------
//	Supervisor (lvl 2)
//-----------------------------------------

export const supervisor = {
  listByDealerId: (id) => http.get(`/supervisor-list/${id}`),
  listByCoWorkerId: (id) => http.get(`/supervisor-list-by-coworker/${id}`),
};

//-----------------------------------------
//	Client
//-----------------------------------------

export const client = {
  clientList: (query) => http.get('/clients', { params: query }),
  contactList: ({client_id, ...query}) => http.get('/contacts', { params: { client_id: client_id ? client_id.replace(' ', '') : null, ...query} }),
  removeContact: (id) => http.delete(`/contacts/${id}`),
  createContact: (payload) => http.post(`/contacts`, payload),
  fetchContact: (payload) => http.get(`/contacts/${payload.id}`),
  updateContact: (payload) => http.put(`/contacts/${payload.id}`, payload),
  create: (query) => http.post('/clients', query),
  clientArray: (query) => http.get('/clients-list', { params: query }),
  current: (id) => http.get(`/clients/${id}`),
  edit: (id, payload) => http.put(`/clients/${id}`, payload),
  changeStatus: (id) => http.put(`/clients/${id}/change-status`),
  clientsByCompanyName: (company_name, except_id) => http.get('/clients-list-by-company-name', {
    params: {
      company_name,
      except_id,
    },
  }),
  updateLocations: (id, payload) => http.put(`/clients-update-locations/${id}`, payload),
};


//-----------------------------------------
//	ClientVehicleImport
//-----------------------------------------

export const clientVehicleImport = {
  list: (query) => http.get('/client-vehicle-imports', { params: query }),
  uploadImportFile: (payload) => {
    const fd = new FormData();
    fd.append('file', payload.file);
    return http.post('/client-vehicle-imports', fd);
  },
  getImportInfo: (id) => http.get(`/client-vehicle-imports/${id}`),
  canUseImport: () => http.get(`/client-vehicle-imports-can-use-import`),
};

//-----------------------------------------
//	Ios Links Import
//-----------------------------------------

export const iosLinksImport = {
  list: (query) => http.get('/ios-links-imports', { params: query }),
  uploadImportFile: (payload) => {
    const fd = new FormData();
    fd.append('file', payload.file);
    return http.post('/ios-links-imports', fd);
  },
  getImportInfo: (id) => http.get(`/ios-links-imports/${id}`),
  canUseImport: () => http.get(`/ios-links-imports-can-use-import`),
};

//-----------------------------------------
//	Coverage goals
//-----------------------------------------


export const coverage_goals = {
  coverageGoals: (query) => http.post('/coverage-goals', query),
  setCoverageGoals: (id, query) => http.put(`/coverage-goals/${id}`, query),
};

//-----------------------------------------
//	Coverage goals v2
//-----------------------------------------


export const coverage_goals_v2 = {
	coverageGoals: (query) => http.post('/v2/coverage-goals', query),
	setCoverageGoals: (id, query) => http.put(`/v2/coverage-goals/${id}`, query),
};

//-----------------------------------------
//	Territorial (lvl 4)
//-----------------------------------------

export const territorial = {
  create: (query) => http.post('/territorial-manager', query),
  current: (id) => http.get(`/territorial-manager/${id}`),
  edit: (id, query) => http.put(`/territorial-manager/${id}`, query),
  changePasswordLogin: (id) => http.put(`/territorial-manager/${id}/change-password-login`),
  activeList: () => http.get('/territorial-manager-list'),
  list: (query) => http.get('/territorial-manager', { params: query }),
  uploadPhoto: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    return http.post(`/territorial-manager/${id}/upload-photo`, fd);
  },
  changeStatus: (id) => http.put(`/territorial-manager/${id}/change-status`),
};

//-----------------------------------------
//	Locations
//-----------------------------------------

export const country = {
  create: (query) => http.post('/countries', query),
  current: (id) => http.get(`/countries/${id}`),
  edit: (id, query) => http.put(`/countries/${id}`, query),
  list: (query) => http.get('/countries', { params: query }),
  changeStatus: (id) => http.put(`/countries/${id}/change-status`),
  activeList: (clients) => {
    if (window._location.includes('/customers')) {
      return http.get('/countries-active-list', {
        params: {
          clients: clients ?? true
        }
      });
    }
    return http.get('/countries-active-list');
  },
};

export const region = {
  create: (query) => http.post('/regions', query),
  current: (id) => http.get(`/regions/${id}`),
  edit: (id, query) => http.put(`/regions/${id}`, query),
  list: (query) => http.get('/regions', { params: query }),
  activeList: (query) => http.get('/regions-active-list', { params: query }),
  changeStatus: (id) => http.put(`/regions/${id}/change-status`),
  activeListByCountry: (country_id, clients) => {
    if (window._location.includes('/customers')) {
      return http.get('/regions-active-list-by-country', {
        params: {
          country_id,
          clients: clients ?? true
        },
      });
    }
    return http.get('/regions-active-list-by-country', {
      params: {
        country_id,
      },
    });
  },
};

export const rayon = {
  create: (query) => http.post('/districts', query),
  current: (id) => http.get(`/districts/${id}`),
  edit: (id, query) => http.put(`/districts/${id}`, query),
  list: (query) => http.get('/districts', { params: query }),
  changeStatus: (id) => http.put(`/districts/${id}/change-status`),
  activeList: () => http.get('/districts-active-list'),
  activeListByRegions: (region_ids, clients) => {
    if (window._location.includes('/customers')) {
      return http.get('/district-list-by-regions-ids', {
        params: {
          'region_ids[]': region_ids,
          clients: clients ?? true
        },
      });
    }
    return http.get('/district-list-by-regions-ids', {
      params: {
        'region_ids[]': region_ids,
      },
    });
  },
};

//-----------------------------------------
//	Job Title
//-----------------------------------------

export const job = {
  activeList: () => http.get('/jobs-titles-active-list'),
  create: (query) => http.post('/jobs-titles', query),
  list: (query) => http.get('/jobs-titles', { params: query }),
  changeStatus: (id) => http.put(`/jobs-titles/${id}/change-status`),
  current: (id) => http.get(`/jobs-titles/${id}`),
  edit: (id, query) => http.put(`/jobs-titles/${id}`, query),
};

//-----------------------------------------
//	Factories
//-----------------------------------------

export const factory = {
  create: (query) => http.post('/factories', query),
  list: (query) => http.get('/factories', { params: query }),
  changeStatus: (query) => http.put(`/factories/${query}/change-status`),
  current: (id) => http.get(`/factories/${id}`),
  edit: ({ id, ...query }) => http.put(`/factories/${id}`, query),
};

//-----------------------------------------
//	Product Line Group
//-----------------------------------------

export const productLineGroup = {
  create: (query) => http.post('/product-line-group', query),
  edit: (id, query) => http.put(`/product-line-group/${id}`, query),
  current: (id) => http.get(`/product-line-group/${id}`),
  changeStatus: (id) => http.put(`/product-line-group/${id}/change-status`),
  activeList: () => http.get('/product-line-group-list'),
  activeListByPlatform: (platform_id) => http.get('/product-line-group-active-list', {
    params: {
      platform_id,
    },
  }),
};

//-----------------------------------------
//	Product Line
//-----------------------------------------

export const productLine = {
  create: (query) => http.post('/product-line', query),
  edit: (id, query) => http.put(`/product-line/${id}`, query),
  current: (id) => http.get(`/product-line/${id}`),
  changeStatus: (id) => http.put(`/product-line/${id}/change-status`),
  activeListByProductLineGroup: (product_line_group_id) => http.get('/product-line-active-list', {
    params: {
      product_line_group_id,
    },
  }),
};

//-----------------------------------------
//	Farm Size
//-----------------------------------------

export const farmSize = {
  create: (query) => http.post('/farm-size-category', query),
  edit: (id, query) => http.put(`/farm-size-category/${id}`, query),
  current: (id) => http.get(`/farm-size-category/${id}`),
  delete: (id) => http.delete(`/farm-size-category/${id}`),
  list: (query) => http.get('/farm-size-category', { params: query }),
};

//-----------------------------------------
//	Equipment Group
//-----------------------------------------

export const equipmentGroup = {
  create: (query) => http.post('/equipment-group', query),
  edit: (id, query) => http.put(`/equipment-group/${id}`, query),
  current: (id) => http.get(`/equipment-group/${id}`),
  changeStatus: (id) => http.put(`/equipment-group/${id}/change-status`),
  activeList: () => http.get('/equipment-group-list'),
  activeListByManufacturer: (query) => http.get(`/equipment-group-list-with-products-by-manufacturer`, {
    params: {
      manufacture_id: query
    }
  }),
  activeListByManufacturerAndPlatform: (query) => http.get(`/equipment-group-list-with-products-by-manufacturer-and-platform`, {
    params: {
      ...query
    }
  }),
  activeListByPlatform: (platformId) => http.get('/equipment-group-active-list-by-platform', {
    params: {
      platform_id: platformId,
    },
  }),
  activeListByProductLine: (product_line_id) => http.get('/equipment-group-active-list', {
    params: {
      product_line_id,
    },
  }),
};

//-----------------------------------------
//	Composite Level
//-----------------------------------------

export const compositeLevel = {
  create: (query) => http.post('/composite-level', query),
  edit: (id, query) => http.put(`/composite-level/${id}`, query),
  current: (id) => http.get(`/composite-level/${id}`),
  changeStatus: (id) => http.put(`/composite-level/${id}/change-status`),
  activeListByEquipmentGroup: (equipment_group_id) => http.get('/composite-level-active-list', {
    params: {
      equipment_group_id,
    },
  }),
};

//-----------------------------------------
//	Estimate Description
//-----------------------------------------

export const estimateDescription = {
  create: (query) => http.post('/estimate-description', query),
  edit: (id, query) => http.put(`/estimate-description/${id}`, query),
  current: (id) => http.get(`/estimate-description/${id}`),
  changeStatus: (id) => http.put(`/estimate-description/${id}/change-status`),
  activeListWithProductsActiveModel: (query) => http.get('/estimate-description-list-with-products-active-model', {
    params: {
      query: query.replace(' ', '')
    }
  }),
  activeListByEqupmentGroup: (equipmentGroupId) => http.get('/estimate-description-active-list-by-eq-group', {
    params: {
      equipment_group_id: equipmentGroupId,
    },
  }),
  activeListByCompositeLevel: (composite_level_id) => http.get('/estimate-description-active-list', {
    params: {
      composite_level_id,
    },
  }),
  activeListByManufacturerAndEq: (payload) => http.get('/estimate-description-list-with-products-by-manufacturer-and-eq-group', {
    params: {
      ...payload
    },
  }),
	activeListByProductLineGroup: (payload) => http.get('/estimate-description-list', {
		params: {
			product_line_group_id: payload
		},
	}),
};

//-----------------------------------------
//	OrderScheduledHistory
//-----------------------------------------

export const orderScheduledHistory = {
  listByDealer: (query) => http.get('/order-scheduled-history', {
    params: query
  }),
  listByTotal: (query) => http.get('/order-scheduled-history-total', {
    params: query
  }),
  changeHistoryByDealer: ({ id, count_in_units }) => http.put(`/order-scheduled-history/${id}`, {
    id,
    count_in_units
  })
};

//-----------------------------------------
//	OrderScheduled
//-----------------------------------------

export const ordersScheduled = {
  listByDealer: (query) => http.get('/order-scheduled', {
    params: query
  }),
  listByTotal: (query) => http.get('/order-scheduled-total', {
    params: query
  }),
};

//-----------------------------------------
//	Model Description
//-----------------------------------------

export const modelDescription = {
  create: (query) => http.post('/model-description', query),
  edit: (id, query) => http.put(`/model-description/${id}`, query),
  current: (id) => http.get(`/model-description/${id}`),
  changeStatus: (id) => http.put(`/model-description/${id}/change-status`),
  listByEquipmentGroup: (id) => http.get(`/model-description-active-list-by-equip-group`, {
    params: {
      equipment_group_id: id
    }
  }),
  listByManufacturerWithProducts: (action) => {
    const { payload } = action;
    return http.get(`/model-description-active-list-by-manufacture-with-products`, {
      params: {
        ...payload
      }
    });
  },
  activeListByEstimateDescription: (payload) => http.get('/model-description-active-list', {
    params: {
      ...payload,
    },
  }),
  activeListByProductLines: ({ product_line_ids, query }) => http.get('/model-description-active-list', {
	params: {
		product_line_ids,
		query,
	},
  }),
  activeListByManufacturerAndEstimateDescription: (payload) => http.get('/model-description-list-with-products-by-manufacture-and-estimate-desc', {
    params: {
      ...payload
    },
  }),
};

//-----------------------------------------
//	Model Description
//-----------------------------------------

export const baseMachineryDescription = {
  create: (query) => http.post('/base-machinery-description', query),
  edit: (id, query) => http.put(`/base-machinery-description/${id}`, query),
  current: (id) => http.get(`/base-machinery-description/${id}`),
  changeStatus: (id) => http.put(`/base-machinery-description/${id}/change-status`),
  activeList: () => http.get('/base-machinery-description-active-list'),
  activeListByModelDescription: (payload) => http.get('/base-machinery-description-active-list', {
    params: {
      ...payload,
    },
  }),
};

//-----------------------------------------
//	Co-worker (lvl 3.1)
//-----------------------------------------

export const coWorker = {
  create: (query) => http.post('coworker', query),
  edit: (id, query) => http.put(`/coworker/${id}`, query),
  current: (id) => http.get(`/coworker/${id}`),
  changeStatus: (id) => http.put(`/coworker/${id}/change-status`),
  sendSign: (id) => http.put(`/coworker/${id}/change-password-login`),
  activeList: () => http.get('/coworker-list'),
  listByDealer: ({ dealerId }) => http.get(`/coworker-list-by-dealer/${dealerId}`),
  uploadPhoto: (id, photo) => {
    const fd = new FormData();
    fd.append('photo', photo[0]);
    http.post(`/coworker/${id}/upload-photo`, fd);
  },
};

//-----------------------------------------
//	Staff (all lvl)
//-----------------------------------------

export const staff = {
  list: (query) => http.get('/staff', { params: query }),
};

//-----------------------------------------
//	Country Manager (lvl 5)
//-----------------------------------------

export const countryManager = {
  current: (id) => http.get(`/country-manager/${id}`),
};


export const profile = {
  //-----------------------------------------
  //	Employees
  //-----------------------------------------
  employeesInfo: () => http.get('/me'),
  employeesEdit: (payload) => http.put('/me', payload),
  changeLanguage: (payload) => http.put('/change-language', payload),
  employeesPhoto: (fd) => http.post('/me/upload-photo', fd),
  //-----------------------------------------
  //	Dealer
  //-----------------------------------------
  dealerInfo: () => http.get('/dealer-profile/info'),
  dealerEdit: (payload) => http.put('/dealer-profile/info', payload),
  dealerPhoto: (fd) => http.post('/dealer-profile/info/upload-photo', fd),
  //-----------------------------------------
  //	Co-Worker
  //-----------------------------------------
  coWorkerInfo: () => http.get('/coworker-profile/info'),
  coWorkerEdit: (payload) => http.put('/coworker-profile/info', payload),
  coWorkerPhoto: (payload) => http.post('/coworker-profile/info/upload-photo', payload),
  //-----------------------------------------
  //	Territorial
  //-----------------------------------------
  territorialInfo: () => http.get('/territorial-manager-profile/info'),
  territorialEdit: (query) => http.put('/territorial-manager-profile/info', query),
  territorialPhoto: (query) => http.post('/territorial-manager-profile/info/upload-photo', query),
  //-----------------------------------------
  //	Countyr Manager
  //-----------------------------------------
  countryManagerInfo: () => http.get('/country-manager-profile/info'),
  countryManagerEdit: (query) => http.put('/country-manager-profile/info', query),
  countryManagerPhoto: (query) => http.post('/country-manager-profile/info/upload-photo', query),
  //-----------------------------------------
  //	Product sales specialist
  //-----------------------------------------
  productSalesSpecialistInfo: () => http.get('/product-sales-specialist-profile/info'),
  productSalesSpecialistEdit: (query) => http.put('/product-sales-specialist-profile/info', query),
  productSalesSpecialistPhoto: (query) => http.post('/product-sales-specialist-profile/info/upload-photo', query),
};

//-----------------------------------------
//	Currency
//-----------------------------------------

export const currencies = {
  create: ({ query }) => http.post('/currencies', query),
  info: ({ id }) => http.get(`/currencies/${id}`),
  update: ({ id, query }) => http.put(`/currencies/${id}`, query),
  remove: ({ id }) => http.delete(`/currencies/${id}`),
  list: ({ query }) => http.get('/currencies', { params: query }),
  changeStatus: ({ id }) => http.put(`/currencies/${id}/change-status`),
};

//-----------------------------------------
//	Deal Status
//-----------------------------------------

export const dealStatuses = {
  create: ({ query }) => http.post('/deal-statuses', query),
  info: ({ id }) => http.get(`/deal-statuses/${id}`),
  update: ({ id, query }) => http.put(`/deal-statuses/${id}`, query),
  remove: ({ id }) => http.delete(`/deal-statuses/${id}`),
  list: ({ query }) => http.get('/deal-statuses', { params: query }),
  changeStatus: ({ id }) => http.put(`/deal-statuses/${id}/change-status`),
};

//-----------------------------------------
//	Deal Actions
//-----------------------------------------

export const dealActions = {
  create: ({ query }) => http.post('/deal-actions', query),
  info: ({ id }) => http.get(`/deal-actions/${id}`),
  update: ({ id, query }) => http.put(`/deal-actions/${id}`, query),
  remove: ({ id }) => http.delete(`/deal-actions/${id}`),
  list: ({ query }) => http.get('/deal-actions', { params: query }),
  changeStatus: ({ id }) => http.put(`/deal-actions/${id}/change-status`),
};

//-----------------------------------------
//	Deal Results
//-----------------------------------------

export const dealResults = {
  create: ({ query }) => http.post('/deal-results', query),
  info: ({ id }) => http.get(`/deal-results/${id}`),
  update: ({ id, query }) => http.put(`/deal-results/${id}`, query),
  remove: ({ id }) => http.delete(`/deal-results/${id}`),
  list: ({ query }) => http.get('/deal-results', { params: query }),
  changeStatus: ({ id }) => http.put(`/deal-results/${id}/change-status`),
};

//-----------------------------------------
//	Deal Lost Reasons
//-----------------------------------------

export const dealLostReasons = {
  create: ({ query }) => http.post('/deal-lost-reasons', query),
  info: ({ id }) => http.get(`/deal-lost-reasons/${id}`),
  update: ({ id, query }) => http.put(`/deal-lost-reasons/${id}`, query),
  remove: ({ id }) => http.delete(`/deal-lost-reasons/${id}`),
  list: ({ query }) => http.get('/deal-lost-reasons', { params: query }),
  changeStatus: ({ id }) => http.put(`/deal-lost-reasons/${id}/change-status`),
  activeList: () => http.get(`/deal-lost-reasons-list`),
};

//-----------------------------------------
//	Size Parameters
//-----------------------------------------

export const sizeParameters = {
  create: (query) => http.post('/size-parameter', query),
  list: (query) => http.get('/size-parameter', { params: query }),
  changeStatus: (id) => http.put(`/size-parameter/${id}/change-status`),
  item: (id) => http.get(`/size-parameter/${id}`),
  saveItem: (id, query) => http.put(`/size-parameter/${id}`, query),
  activeList: () => http.get('/size-parameter-active-list'),
};

//-----------------------------------------
//	BOED
//-----------------------------------------

export const BOED = {
  list: (query) => http.get('/boed', {
    params: query,
  }),
};

//-----------------------------------------
//	BOED_v2
//-----------------------------------------

export const BOED_v2 = {
	list: (query) => http.get('/v2/boed', {
		params: query,
	}),
};

//-----------------------------------------
//	brand loyalty
//-----------------------------------------

export const brandLoyalty = {
  getData: (query) => http.get('/brand-loyalty', {
    params: query,
  }),
};

//-----------------------------------------
//	brand loyalty v2
//-----------------------------------------

export const brandLoyalty_v2 = {
	getData: (query) => http.get('/v2/brand-loyalty', {
		params: query,
	}),
};

//-----------------------------------------
//	products quantity
//-----------------------------------------

export const productsQuantity = {
  getData: (query) => http.get('/product-quantity', {
    params: query,
  }),
};

//-----------------------------------------
//	products quantity v2
//-----------------------------------------

export const productsQuantity_v2 = {
	getData: (query) => http.get('/v2/product-quantity', {
		params: query,
	}),
};

//-----------------------------------------
//	Lost to companies
//-----------------------------------------

export const lostToCompanies = {
  getData: (query) => http.get('/company-lost-deals', {
    params: query,
  }),
  getFullData: (query) => http.get('/company-lost-deals-list', {
	params: query,
  }),
  create: (query) => http.post('/company-lost-deals', query),
  current: (id) => http.get(`/company-lost-deals/${id}`),
  edit: (id, query) => http.put(`/company-lost-deals/${id}`, query),
  change_status: (id) => http.put(`/company-lost-deals/${id}/change-status`),
  changeActualSales: (id) => http.put(`/company-lost-deals/${id}/change-actual-sales`)
};

//-----------------------------------------
//	Sales manager efficiency
//-----------------------------------------

export const salesManagerEfficiency = {
  getData: (query) => http.get('/sales-manager-efficiency', {
    params: query
  })
};

//-----------------------------------------
//	Sales manager efficiency v2
//-----------------------------------------

export const salesManagerEfficiency_v2 = {
	getData: (query) => http.get('/v2/sales-manager-efficiency', {
		params: query
	})
};

//-----------------------------------------
//	Maps
//-----------------------------------------

export const maps = {
	getData: (query) => http.get('/maps', {
		params: query
	}),
	getChartData: (query) => http.get('/maps-chart', {
		params: query
	}),
};

//-----------------------------------------
//	Districts coordinates
//-----------------------------------------

export const districtsCoordinates = {
	getData: (query) => http.get('/districts-coordinates', {
		params: query
	}),
	edit: (id, query) => http.put(`/districts-coordinates/${id}`, query),
};

//-----------------------------------------
//	Regions coordinates
//-----------------------------------------

export const regionsCoordinates = {
	getData: (query) => http.get('/regions-coordinates', {
		params: query
	}),
	edit: (id, query) => http.put(`/regions-coordinates/${id}`, query),
};

//-----------------------------------------
//	Deal
//-----------------------------------------

export const deal = {
  actionList: () => http.get('/deal-actions-active-list'),
  statusesActiveList: () => http.get('/deal-statuses-active-list'),
  resultsActiveList: () => http.get('/deal-results-active-list'),
  actionsActiveList: () => http.get('/deal-actions-active-list'),
  getManagers: (id) => http.get(`/deals/${id}/get-sales-for-deal`),
  edit: ({ id, ...payload }) => http.put(`/deals/${id}`, payload),
  delete: (id) => http.delete(`/deals/${id}`),
  getClients: (id) => http.get(`/get-clients-for-deal/${id}`),
  changeManager: ({ dealId, managerId }) => http.put(`/deals/${dealId}/change-manager`, { sales_id: managerId }),
  changeClient: ({ dealId, clientId }) => http.put(`/deals/${dealId}/change-client`, { client_id: clientId }),
  history: (id) => http.get(`/deals/${id}/history`),
  current: (id) => http.get(`/deals/${id}`),
  list: ({client, ...query}) => http.get('/deals', {
    params: {client: client ? client.replace(' ', '') : null, ...query}
  }),
	fullList: (query) => http.post('/deals-all', query),
  archivedList : (query) => http.get('/archived-deals', {
    params: query
  }),
  getDealItem: (id) =>{
    return  http.get(`/deals-item/${id}`);
  },
  changeDealItem: ({ payload }) => {
    const { id, ...values } = payload;
    return http.put(`/deals-item/${id}`, {
      ...values
    })
  },
  archiveHistoryItemStatus: ({ dealId, itemId }) => http.put(`/deals/${dealId}/deal-statuses/${itemId}`),
  archiveHistoryItemResult: ({ dealId, itemId }) => http.put(`/deals/${dealId}/deal-results/${itemId}`),
  changeFiscalYear: ({ dealId, ...values }) => http.put(`/deals/${dealId}/change-fiscal-year`, {
  	...values
  })
};

//-----------------------------------------
//	Years
//-----------------------------------------

export const years = {
	list: () => http.get('/years'),
	salesPlansList: () => http.get('/years-for-sales-man-plans'),
	fyTypeList: (query) => http.get('/get-fiscal-year', {
		params: query,
	}),
};

//-----------------------------------------
//	Actual sales plan import
//-----------------------------------------

export const actualSalesPlanImport = {
	list: (query) => http.get('/actual-sales-plan-imports', { params: query }),
	uploadImportFile: (payload) => {
		const fd = new FormData();
		fd.append('file', payload.file);
		return http.post('/actual-sales-plan-imports', fd);
	},
	getImportInfo: (id) => http.get(`/actual-sales-plan-imports/${id}`),
	canUseImport: () => http.get(`/actual-sales-plan-imports-can-use-import`),
};

//-----------------------------------------
//	Actual sales plan draft
//-----------------------------------------

export const actualSalesPlanDraft = {
	list: (query) => http.get('/actual-sales-plan-drafts', { params: query }),
	current: (id) => http.get(`/actual-sales-plan-drafts/${id}`),
	update: (id, query) => http.put(`/actual-sales-plan-drafts/${id}`, query),
	delete: (id) => http.delete(`/actual-sales-plan-drafts/${id}`),
	massDelete: (payload) => http.delete(`/actual-sales-plan-drafts-mass-delete`, { params: payload }),
	restore: (id) => http.put(`/actual-sales-plan-drafts/${id}/restore`),
	verify: (id) => http.put(`/actual-sales-plan-drafts/${id}/verify`),
};

//-----------------------------------------
//	Actual sales plans
//-----------------------------------------

export const actualSalesPlans = {
	list: (query) => http.get('/actual-sales-plans', { params: query }),
	current: (id) => http.get(`/actual-sales-plans/${id}`),
	update: (id, query) => http.put(`/actual-sales-plans/${id}`, query),
	delete: (id) => http.delete(`/actual-sales-plans/${id}`),
	setParent: (id, query) => http.put(`/actual-sales-plans/${id}/set-parent`, query),
	makeCatalogue: (id) => http.put(`/actual-sales-plans/${id}/make-catalogue`),
};

//-----------------------------------------
//	Actual sales plans summary
//-----------------------------------------

export const actualSalesPlansSummary = {
	list: (query) => http.get('/actual-sales-plans-summary', { params: query }),
};

//-----------------------------------------
//	Actual sales plan maps
//-----------------------------------------

export const actualSalesPlanMaps = {
	getData: (query) => http.get('/maps-actual-sales-plan', {
		params: query
	}),
	getChartData: (query) => http.get('/maps-chart-actual-sales-plan', {
		params: query
	}),
};

//-----------------------------------------
//	Actual sales plan deal maps
//-----------------------------------------

export const actualSalesPlanDealMaps = {
	getData: (query) => http.get('/maps-deal-actual-sales-plan', {
		params: query
	}),
	getChartData: (query) => http.get('/maps-chart-deal-actual-sales-plan', {
		params: query
	}),
};

//-----------------------------------------
//	Service codes
//-----------------------------------------

export const serviceCodes = {
	list: (payload) => http.get('/service-codes', { params: payload }),
	current: (id) => http.get(`/service-codes/${id}`),
	create: (payload) => http.post('/service-codes', payload),
	update: (id, query) => http.put(`/service-codes/${id}`, query),
	changeStatus: (id) => http.put(`/service-codes/${id}/change-status`),
	changeActual: (id) => http.put(`/service-codes/${id}/change-actual`),
	deleteDrafts: (id) => http.delete(`/service-codes/${id}/delete-actual-sales-plan-drafts`),
	codesArray: (payload) => http.get('/service-codes-list', { params: payload }),
};

//-----------------------------------------
//	Dashboard
//-----------------------------------------

export const dashboard = {
	salesActivity: (query) => http.get('/dashboard/sales-activity', { params: query, }),
	salesTotal: (query) => http.get('/dashboard/sales-total', { params: query, }),
	productMix: (query) => http.get('/dashboard/product-mix', { params: query, }),
};

//-----------------------------------------
//	Translates
//-----------------------------------------

export const translates = {
  list: (query) => http.get('/translates', {
    params: query,
  }),
  edit: ({ id, ...query }) => http.put(`/translates/${id}`, query),
  moduleList: () => http.get(`/translates-module`)
};

export const techniques = {
  create: (payload) => http.post('/client-vehicles', payload),
  current: ({ id }) => http.get(`/client-vehicles/${id}`),
  update: ({ id, ...payload }) => http.put(`/client-vehicles/${id}`, payload),
  list: (payload) => http.get(`/client-vehicles`, {params: payload}),
  remove: ({ id }) => http.delete(`/client-vehicles/${id}`),
  changeStatus: ({ id }) => http.put(`/client-vehicles/${id}/change-status`)
};
